import { Anchor, Box, LoadingOverlay } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { ApiError, getApiError } from '@/api/helpers/apiError'
import { useContactInfoPopup } from '@/hooks/useContactInfoPopup'
import { RouterPath } from '@/router/paths'
import { useLogin } from '@/session/useLogin'
import { AuthLayout } from '../layout/AuthLayout/AuthLayout'
import { PlatformAlert } from '../ui-shared/PlatformAlert/PlatformAlert'
import { FormValues, LoginForm } from './LoginForm'

type LoginErrorProps = {
  error: ApiError
  onContactInfoClick: () => void
}

const LoginError = ({ error, onContactInfoClick }: LoginErrorProps) => {
  if (error.status === 401) {
    return <FormattedMessage id="login.errors.invalidCredentials" />
  }

  if (error.status === 403) {
    return (
      <FormattedMessage
        id="subscriptions.noActiveSubscription"
        values={{
          link: (chunks) => (
            <Anchor
              size="xs"
              underline="always"
              component="button"
              type="button"
              onClick={onContactInfoClick}
            >
              {chunks}
            </Anchor>
          )
        }}
      />
    )
  }

  return <FormattedMessage id="login.errors.other" />
}

export const LoginPage = () => {
  const { login, isPending, isError, error } = useLogin()
  const { open: openContactInfo } = useContactInfoPopup()

  const onSubmit = (formValues: FormValues) => {
    if (!isPending) {
      void login(formValues)
    }
  }

  return (
    <AuthLayout>
      <Box pos="relative">
        <LoadingOverlay visible={isPending} />

        {isError && (
          <PlatformAlert variant="error" mb="md" compact>
            <LoginError
              error={getApiError(error)}
              onContactInfoClick={openContactInfo}
            />
          </PlatformAlert>
        )}

        <LoginForm
          forgotPasswordLink={RouterPath.forgotPassword}
          onSubmit={onSubmit}
        />
      </Box>
    </AuthLayout>
  )
}
