import {
  Box,
  Center,
  Group,
  Loader,
  Stack,
  Text,
  ThemeIcon,
  Title
} from '@mantine/core'
import { useDisclosure, useInterval } from '@mantine/hooks'
import { IconRocket } from '@tabler/icons-react'
import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router'
import { DeploymentDetails } from '@/components/deployments/deployment-list/DeploymentDetails/DeploymentDetails'
import { PageWithFixedToolbar } from '@/components/layout/PageWithFixedToolbar/PageWithFixedToolbar'
import { AddButtonCard } from '@/components/ui-shared/AddButtonCard/AddButtonCard'
import { ErrorWithReload } from '@/components/ui-shared/ErrorWithReload/ErrorWithReload'
import { useHasPermissions } from '@/permissions/useHasPermissions'
import { useApplicationContext } from '@/providers/ApplicationContext'
import { useGetDeployments } from '@/queries/deploymentQueries'
import { ApplicationNestedPath, buildAppLink } from '@/router/paths'
import { ApplicationType } from '@/types/app'
import { DeploymentStatus } from '@/types/deployment'
import { CrudAction, Resource } from '@/types/permissions'
import { DeploymentProcessBar } from './DeploymentProcessBar/DeploymentProcessBar'
import { DeploymentToolbar } from './DeploymentToolbar/DeploymentToolbar'
import { RemoveDeploymentConfirmation } from './RemoveDeploymentConfirmation/RemoveDeploymentConfirmation'

const POLL_INTERVAL = 10000

export const DeploymentsPage = () => {
  const navigate = useNavigate()
  const { application } = useApplicationContext()

  const [
    isRemoveDeploymentModalOpen,
    { open: openRemoveDeploymentModal, close: closeRemoveDeploymentModal }
  ] = useDisclosure(false)

  const [canCreateDeployment, canUpdateDeployment, canDeleteDeployment] =
    useHasPermissions(Resource.Deployments, [
      CrudAction.Create,
      CrudAction.Update,
      CrudAction.Delete
    ])

  const { data, isError, isLoading, isFetching, refetch } = useGetDeployments(
    application?.id || ''
  )

  const { start, stop, active } = useInterval(
    () => void refetch(),
    POLL_INTERVAL
  )

  useEffect(() => {
    if (data && !active) {
      start()
    }
  }, [data, active, start])

  // Cleanup function to stop the interval when the component is unmounted
  useEffect(() => {
    return stop
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const deployments = data?.pages.flatMap((page) => page.results) ?? []

  const deploymentInProgress = deployments.some(
    (deployment) => deployment.status === DeploymentStatus.InProgress
  )

  const deploymentRemovalInProgress = deployments.some(
    (deployment) => deployment.status === DeploymentStatus.Removing
  )

  const deploymentOperationInProgress =
    deploymentInProgress || deploymentRemovalInProgress

  const handleOnReload = () => {
    void refetch()
  }

  const goToCreateDeploymentsPage = () => {
    void navigate(
      buildAppLink(
        application?.id || '',
        ApplicationNestedPath.createDeployment
      )
    )
  }

  const goToEventsPage = () => {
    void navigate(
      buildAppLink(application?.id || '', ApplicationNestedPath.events)
    )
  }

  const handleDeploymentRemoved = () => {
    closeRemoveDeploymentModal()
    void refetch()
  }

  if (isError) {
    return (
      <ErrorWithReload onReload={handleOnReload}>
        <FormattedMessage id="deployments.details.fetchError" />
      </ErrorWithReload>
    )
  }

  return (
    <PageWithFixedToolbar
      toolbar={
        <Group wrap="nowrap" gap="xs">
          <ThemeIcon variant="transparent" size="md">
            <IconRocket style={{ width: '90%', height: '90%' }} />
          </ThemeIcon>

          <Title order={3}>
            <FormattedMessage id="deployments.title" />
          </Title>
        </Group>
      }
    >
      {isLoading && (
        <Center mih={120}>
          <Loader />
        </Center>
      )}

      {deployments.length === 0 && !isLoading && (
        <Stack align="center" justify="center" mih={400} gap="xl">
          <Text>
            <FormattedMessage id="deployments.noDeployments" />
          </Text>

          {canCreateDeployment && (
            <Box w={200}>
              <AddButtonCard
                height={175}
                label={
                  <FormattedMessage
                    id="deployments.deployModel"
                    values={{
                      count: 1
                    }}
                  />
                }
                onClick={goToCreateDeploymentsPage}
              />
            </Box>
          )}
        </Stack>
      )}

      {deployments.length > 0 && (
        <Stack>
          <DeploymentToolbar
            applicationName={application?.name || ''}
            isFetching={isFetching}
            isEditDisabled={
              deploymentOperationInProgress || !canUpdateDeployment
            }
            isRemoveDisabled={
              deploymentOperationInProgress || !canDeleteDeployment
            }
            onRefreshClick={() => void refetch()}
            onViewEventsClick={goToEventsPage}
            onEditClick={goToCreateDeploymentsPage}
            onRemoveClick={openRemoveDeploymentModal}
          />

          <Box
            style={{
              opacity: isFetching ? 0.7 : 1
            }}
          >
            <DeploymentDetails
              appId={application?.id || ''}
              appType={application?.application_type as ApplicationType}
              deployments={deployments}
              onRefresh={handleOnReload}
            />
          </Box>
        </Stack>
      )}

      <DeploymentProcessBar
        isShown={deploymentInProgress || deploymentRemovalInProgress}
        processType={deploymentRemovalInProgress ? 'removal' : 'deployment'}
      />

      <RemoveDeploymentConfirmation
        opened={isRemoveDeploymentModalOpen}
        applicationId={application?.id || ''}
        applicationName={application?.name || ''}
        onClose={closeRemoveDeploymentModal}
        onRemoved={handleDeploymentRemoved}
      />
    </PageWithFixedToolbar>
  )
}
