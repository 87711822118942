import { List, SimpleGrid, ThemeIcon } from '@mantine/core'
import { IconCheck, IconStarFilled } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { PlatformAlert } from '@/components/ui-shared/PlatformAlert/PlatformAlert'

export const LibraryModelGuide = () => {
  return (
    <PlatformAlert showIcon={false} variant="info" px="xl">
      <SimpleGrid cols={2}>
        <List
          size="sm"
          spacing="lg"
          icon={
            <ThemeIcon color="green" size={16} radius="sm">
              <IconCheck style={{ width: '80%', height: '80%' }} />
            </ThemeIcon>
          }
          styles={{
            itemLabel: { fontWeight: 600 }
          }}
          center
        >
          <List.Item>
            <FormattedMessage id="models.library.guide.step1" />
          </List.Item>

          <List.Item>
            <FormattedMessage id="models.library.guide.step2" />
          </List.Item>

          <List.Item>
            <FormattedMessage id="models.library.guide.step3" />
          </List.Item>
        </List>

        <List
          size="sm"
          spacing="lg"
          icon={
            <ThemeIcon variant="transparent" color="blue" size={16}>
              <IconStarFilled style={{ width: '100%', height: '100%' }} />
            </ThemeIcon>
          }
          styles={{
            itemLabel: { fontWeight: 600 }
          }}
          center
        >
          <List.Item>
            <FormattedMessage id="models.library.guide.step4a" />
          </List.Item>

          <List.Item>
            <FormattedMessage id="models.library.guide.step4b" />
          </List.Item>
        </List>
      </SimpleGrid>
    </PlatformAlert>
  )
}
