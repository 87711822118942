import { Card } from '@mantine/core'
import { useModals } from '@mantine/modals'
import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { ContactInfo } from '@/components/ui-shared/ContactInfo/ContactInfo'

export const useContactInfoPopup = () => {
  const intl = useIntl()
  const { openModal } = useModals()

  const openContactInfoPopup = useCallback(() => {
    openModal({
      title: intl.formatMessage({ id: 'contactUs' }),
      children: (
        <Card withBorder>
          <ContactInfo />
        </Card>
      )
    })
  }, [openModal, intl])

  return {
    open: openContactInfoPopup
  }
}
