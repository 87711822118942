import { Badge } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { LabelingType } from '@/types/dataset'
import { LABELING_TYPES_CONFIG_MAP } from './LabelingTypeConfig'

type LabelingTypeChipProps = {
  labelingType: LabelingType
}

export const LabelingTypeChip = ({ labelingType }: LabelingTypeChipProps) => {
  const name = LABELING_TYPES_CONFIG_MAP[labelingType].name

  return (
    <Badge variant="outline" color="brand-primary" size="xs">
      <FormattedMessage id={name} />
    </Badge>
  )
}
