import { Box, Button, PasswordInput, TextInput, Title } from '@mantine/core'
import { isNotEmpty, useForm } from '@mantine/form'

import { FormattedMessage, useIntl } from 'react-intl'
import { RouterPath } from '@/router/paths'

export type FormValues = {
  email: string
  password: string
}

type LoginFormProps = {
  forgotPasswordLink: RouterPath
  onSubmit: (formValues: FormValues) => void
}

export const LoginForm = ({ onSubmit }: LoginFormProps) => {
  const intl = useIntl()

  const form = useForm({
    initialValues: {
      email: '',
      password: ''
    },

    validate: {
      email: isNotEmpty(
        intl.formatMessage({ id: 'validation.enterYourEmail' })
      ),
      password: isNotEmpty(
        intl.formatMessage({ id: 'validation.enterYourPassword' })
      )
    }
  })

  return (
    <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
      <Title order={2} mb="xl">
        <FormattedMessage id="login" />
      </Title>

      <TextInput
        type="email"
        label={<FormattedMessage id="email" />}
        mb="lg"
        autoFocus
        {...form.getInputProps('email')}
      />

      <Box mb="xl">
        <PasswordInput
          label={<FormattedMessage id="password" />}
          {...form.getInputProps('password')}
        />

        {/* Password reset link is temporarily disabled */}
        {/* <Text size="xs" ta="right">
          <Anchor component={Link} to={forgotPasswordLink}>
            <FormattedMessage id="forgotPassword" />
          </Anchor>
        </Text> */}
      </Box>

      <Button type="submit" mb="md" fullWidth>
        <FormattedMessage id="login" />
      </Button>
    </form>
  )
}
