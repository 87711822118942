import { Button } from '@mantine/core'
import { IconPhone } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { useContactInfoPopup } from '@/hooks/useContactInfoPopup'

export const ContactUsButton = () => {
  const { open: openContactInfo } = useContactInfoPopup()

  return (
    <Button
      color="brand-primary.4"
      size="xs"
      leftSection={<IconPhone size={16} />}
      fullWidth
      onClick={openContactInfo}
    >
      <FormattedMessage id="contactUs" />
    </Button>
  )
}
