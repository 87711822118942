import { Button, Grid, Group, Stack, Text } from '@mantine/core'
import { isNotEmpty, useForm } from '@mantine/form'
import { useEffect, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { LabelMultiSelect } from '@/components/labels/LabelMultiSelect/LabelMultiSelect'
import { PlatformAlert } from '@/components/ui-shared/PlatformAlert/PlatformAlert'
import {
  useGetDatasetVersions,
  useGetDatasetsInfinite
} from '@/queries/datasetQueries'
import { DatasetType, LabelingType } from '@/types/dataset'
import { DatasetSelect } from './DatasetSelect'
import { DatasetVersionSelect } from './DatasetVersionSelect'

export type SubmitValues = {
  sourceDatasetId: string
  sourceDatasetVersionId: string
  label_ids: string[]
}

type FormValues = {
  sourceDatasetId: string | null
  sourceDatasetVersionId: string | null
  label_ids: string[]
}

type ImageCropHandlerProps = {
  onSkip: () => void
  onSubmit: (values: SubmitValues) => void
}

export const ImageCropForm = ({ onSkip, onSubmit }: ImageCropHandlerProps) => {
  const intl = useIntl()

  const form = useForm<FormValues>({
    initialValues: {
      sourceDatasetId: null,
      sourceDatasetVersionId: null,
      label_ids: []
    },

    validate: {
      sourceDatasetId: isNotEmpty(),
      sourceDatasetVersionId: isNotEmpty(),
      label_ids: isNotEmpty(
        intl.formatMessage({
          id: 'annotation.classes.required'
        })
      )
    }
  })

  const { sourceDatasetId, sourceDatasetVersionId, label_ids } =
    form.getValues()

  const { data: datasetList, isFetching: isFetchingDatasetList } =
    useGetDatasetsInfinite({
      is_library: false,
      ordering: '-created_at',
      limit: 100,
      dataset_type: DatasetType.Primary,
      labeling_type: LabelingType.ObjectDetection
    })

  const { data: datasetVersionList, isFetching: isFetchingVersionList } =
    useGetDatasetVersions(sourceDatasetId || '', sourceDatasetId !== null)

  const datasets = useMemo(
    () => datasetList?.pages?.flatMap((page) => page.results) || [],
    [datasetList]
  )

  const versions = useMemo(
    () => datasetVersionList?.pages?.flatMap((page) => page.results) || [],
    [datasetVersionList]
  )

  const selectedDataset = datasets.find(
    (dataset) => dataset.id === sourceDatasetId
  )

  const labelOptions =
    selectedDataset?.labels.map((label) => ({
      labelId: label.id,
      name: label.name,
      color: label.color
    })) || []

  // Pre-select first dataset and version
  useEffect(() => {
    if (datasets.length > 0 && sourceDatasetId === null) {
      form.setFieldValue('sourceDatasetId', datasets[0].id)
    }
  }, [datasets, sourceDatasetId, form])

  useEffect(() => {
    if (versions.length > 0 && sourceDatasetVersionId === null) {
      form.setFieldValue('sourceDatasetVersionId', versions[0].id)
    }
  }, [versions, sourceDatasetVersionId, form])

  const handleSubmit = (values: FormValues) => {
    onSubmit(values as SubmitValues)
  }

  const handleDatasetChange = (datasetId: string) => {
    form.setFieldValue('sourceDatasetId', datasetId)
    form.setFieldValue('sourceDatasetVersionId', null)
    form.setFieldValue('label_ids', [])
  }

  return (
    <form noValidate onSubmit={form.onSubmit((values) => handleSubmit(values))}>
      <PlatformAlert variant="info" mb="xl" compact>
        <Stack>
          <Text size="sm">
            <FormattedMessage
              id="datasets.crop.info1"
              values={{
                b: (chunks) => <b>{chunks}</b>
              }}
            />
          </Text>

          <Text size="sm">
            <FormattedMessage id="datasets.crop.info2" />
          </Text>
        </Stack>
      </PlatformAlert>

      <Grid gutter="xs">
        <Grid.Col span={7}>
          <DatasetSelect
            datasets={datasets}
            disabled={isFetchingDatasetList}
            {...form.getInputProps('sourceDatasetId')}
            onChange={(val) => handleDatasetChange(val as string)}
          />
        </Grid.Col>

        <Grid.Col span={5}>
          <DatasetVersionSelect
            versions={versions}
            disabled={isFetchingVersionList}
            {...form.getInputProps('sourceDatasetVersionId')}
          />
        </Grid.Col>
      </Grid>

      {sourceDatasetId && (
        <Stack mt={48} gap="xs">
          <Text size="sm">
            <FormattedMessage id="datasets.crop.selectClasses" />
          </Text>

          <LabelMultiSelect
            values={label_ids}
            options={labelOptions}
            error={form.errors.label_ids}
            onChange={(values) => form.setFieldValue('label_ids', values)}
          />
        </Stack>
      )}

      <Group justify="end" mt={80}>
        <Button miw={160} variant="outline" onClick={onSkip}>
          <FormattedMessage id="datasets.crop.skip" />
        </Button>

        <Button type="submit" miw={160}>
          <FormattedMessage id="datasets.crop" />
        </Button>
      </Group>
    </form>
  )
}
