import { ActionIcon, Badge, Flex, Group, Tooltip } from '@mantine/core'
import { IconMailForward, IconPencil, IconX } from '@tabler/icons-react'
import {
  type MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
  MRT_ToggleGlobalFilterButton,
  MantineReactTable,
  useMantineReactTable
} from 'mantine-react-table'
import { useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { ErrorWithReload } from '@/components/ui-shared/ErrorWithReload/ErrorWithReload'
import { useHasPermissions } from '@/permissions/useHasPermissions'
import { useGetWorkspaceMembers } from '@/queries/workspaceQueries'
import { CrudAction, Resource } from '@/types/permissions'
import {
  WorkspaceMember,
  WorkspaceMemberRole,
  WorkspaceMemberStatus
} from '@/types/workspace'
import { getFormattedDateWithTime } from '@/utils/date'
import { DeactivateMemberModal } from './DeactivateMemberModal'
import { MemberRoleBadge } from './MemberRoleBadge'
import { MemberStatusBadge } from './MemberStatusBadge'
import { ResendInviteModal } from './ResendInviteModal'
import { UpdateMemberModal } from './UpdateMember/UpdateMemberModal'

const DEFAULT_PAGE_SIZE = 10

type MembersTableProps = {
  workspaceName: string
  workspaceId: string
  userId: string
}

type TransformedFilters = Record<string, string | number>

const transformFilters = (
  filters: MRT_ColumnFiltersState
): TransformedFilters => {
  return filters.reduce((acc, { id, value }) => {
    acc[id] = value as string | number
    return acc
  }, {} as TransformedFilters)
}

export const MembersTable = ({
  workspaceId,
  workspaceName,
  userId
}: MembersTableProps) => {
  const intl = useIntl()

  const [canInviteMembers, canUpdateMembers, canDeleteMembers] =
    useHasPermissions(Resource.WorkspaceUsers, [
      CrudAction.Create,
      CrudAction.Update,
      CrudAction.Delete
    ])

  const [memberToUpdate, setMemberToUpdate] = useState<WorkspaceMember | null>(
    null
  )
  const [memberToDeactivate, setMemberToDeactivate] =
    useState<WorkspaceMember | null>(null)

  const [memberToResendInvite, setMemberToResendInvite] =
    useState<WorkspaceMember | null>(null)

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: DEFAULT_PAGE_SIZE
  })
  const [sorting, setSorting] = useState<MRT_SortingState>([])
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([])
  const [globalFilter, setGlobalFilter] = useState('')

  const { data, isError, isFetching, refetch } = useGetWorkspaceMembers({
    pagination: {
      limit: pagination.pageSize,
      offset: pagination.pageIndex * pagination.pageSize
    },
    ordering: `${!sorting[0]?.desc ? '-' : ''}${sorting[0]?.id || 'created_at'}`,
    filter: transformFilters(columnFilters),
    searchQuery: globalFilter,
    workspaceId
  })

  const handleOnReload = () => {
    void refetch()
  }

  const handleUpdateButtonClick = (userWorkspace: WorkspaceMember) => {
    setMemberToUpdate(userWorkspace)
  }

  const handleDeactivateButtonClick = (userWorkspace: WorkspaceMember) => {
    setMemberToDeactivate(userWorkspace)
  }

  const handleResendInviteButtonClick = (userWorkspace: WorkspaceMember) => {
    setMemberToResendInvite(userWorkspace)
  }

  const columns = useMemo<MRT_ColumnDef<WorkspaceMember>[]>(
    () => [
      {
        accessorFn: (row) => {
          return (
            <Group align="center" gap="xs">
              <span>{row.user?.full_name || '-'}</span>

              {row.user?.id === userId && (
                <Badge color="brand-secondary" size="xs" radius="sm">
                  {intl.formatMessage({ id: 'workspace.members.you' })}
                </Badge>
              )}
            </Group>
          )
        },
        id: 'fullName',
        header: intl.formatMessage({ id: 'workspace.members.fullName' }),
        enableColumnFilter: false
      },
      {
        accessorKey: 'user.email',
        id: 'email',
        header: intl.formatMessage({ id: 'email' }),
        enableSorting: false,
        enableColumnFilter: false
      },
      {
        accessorKey: 'created_at',
        id: 'created_at',
        accessorFn: (row) => getFormattedDateWithTime(row.created_at),
        header: intl.formatMessage({ id: 'workspace.members.memberSince' }),
        enableColumnFilter: false
      },
      {
        accessorFn: (row) => <MemberRoleBadge role={row.role} />,
        header: intl.formatMessage({ id: 'workspace.members.role' }),
        id: 'role',
        maxSize: 50,
        enableSorting: false,
        filterVariant: 'select',
        filterFn: 'equals',
        mantineFilterSelectProps: {
          data: [
            {
              label: intl.formatMessage({ id: 'workspace.members.role.owner' }),
              value: String(WorkspaceMemberRole.Owner)
            },
            {
              label: intl.formatMessage({ id: 'workspace.members.role.admin' }),
              value: String(WorkspaceMemberRole.Admin)
            },
            {
              label: intl.formatMessage({ id: 'workspace.members.role.user' }),
              value: String(WorkspaceMemberRole.User)
            }
            // Temporarily hide viewers and labelers from the role dropdown until application sharing is supported
            // {
            //   label: intl.formatMessage({
            //     id: 'workspace.members.role.viewer'
            //   }),
            //   value: String(WorkspaceMemberRole.Viewer)
            // },
            // {
            //   label: intl.formatMessage({
            //     id: 'workspace.members.role.labeler'
            //   }),
            //   value: String(WorkspaceMemberRole.Labeler)
            // }
          ]
        }
      },
      {
        accessorFn: (row) => <MemberStatusBadge status={row.status} />,
        maxSize: 100,
        id: 'status',
        header: intl.formatMessage({ id: 'workspace.members.status' }),
        enableSorting: false,
        filterVariant: 'select',
        filterFn: 'equals',
        mantineFilterSelectProps: {
          data: [
            {
              label: intl.formatMessage({
                id: 'workspace.members.status.active'
              }),
              value: String(WorkspaceMemberStatus.Active)
            },
            {
              label: intl.formatMessage({
                id: 'workspace.members.status.inactive'
              }),
              value: String(WorkspaceMemberStatus.Inactive)
            },
            {
              label: intl.formatMessage({
                id: 'workspace.members.status.pending'
              }),
              value: String(WorkspaceMemberStatus.Pending)
            }
          ]
        }
      }
    ],
    [intl, userId]
  )

  const table = useMantineReactTable({
    columns,
    data: data?.results || [],
    initialState: { density: 'xs' },
    enableColumnActions: false,
    enableRowActions: canInviteMembers || canUpdateMembers || canDeleteMembers,
    positionActionsColumn: 'last',
    rowCount: data?.count,
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    state: {
      sorting,
      globalFilter,
      pagination,
      isLoading: isFetching,
      showProgressBars: isFetching,
      showColumnFilters: true
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    renderToolbarInternalActions: ({ table }) => (
      <MRT_ToggleGlobalFilterButton table={table} />
    ),
    renderRowActions: ({ row }) => {
      if (
        row.original.user?.id === userId ||
        row.original.role === WorkspaceMemberRole.Owner
      ) {
        return null
      }

      return (
        <Flex gap="xs">
          {canUpdateMembers &&
            row.original.status === WorkspaceMemberStatus.Active && (
              <Tooltip
                label={intl.formatMessage({
                  id: 'workspace.members.update'
                })}
              >
                <ActionIcon
                  variant="subtle"
                  color="green"
                  onClick={() => handleUpdateButtonClick(row.original)}
                >
                  <IconPencil />
                </ActionIcon>
              </Tooltip>
            )}

          {canInviteMembers &&
            row.original.status !== WorkspaceMemberStatus.Active && (
              <Tooltip
                label={intl.formatMessage({
                  id: 'workspace.members.invite.resend'
                })}
              >
                <ActionIcon
                  variant="subtle"
                  color="yellow"
                  onClick={() => handleResendInviteButtonClick(row.original)}
                >
                  <IconMailForward />
                </ActionIcon>
              </Tooltip>
            )}

          {canDeleteMembers &&
            row.original.status === WorkspaceMemberStatus.Active && (
              <Tooltip
                label={intl.formatMessage({
                  id: 'workspace.members.deactivate'
                })}
              >
                <ActionIcon
                  variant="subtle"
                  color="red"
                  onClick={() => handleDeactivateButtonClick(row.original)}
                >
                  <IconX />
                </ActionIcon>
              </Tooltip>
            )}
        </Flex>
      )
    }
  })

  if (isError) {
    return (
      <ErrorWithReload onReload={handleOnReload}>
        <FormattedMessage id="workspace.members.apiError" />
      </ErrorWithReload>
    )
  }

  return (
    <>
      <MantineReactTable table={table} />

      <UpdateMemberModal
        workspaceId={workspaceId}
        opened={memberToUpdate !== null}
        member={memberToUpdate}
        onClose={() => setMemberToUpdate(null)}
      />

      <ResendInviteModal
        member={memberToResendInvite}
        workspaceName={workspaceName}
        workspaceId={workspaceId}
        opened={memberToResendInvite !== null}
        onClose={() => setMemberToResendInvite(null)}
      />

      <DeactivateMemberModal
        member={memberToDeactivate}
        workspaceName={workspaceName}
        workspaceId={workspaceId}
        opened={memberToDeactivate !== null}
        onClose={() => setMemberToDeactivate(null)}
      />
    </>
  )
}
