import {
  Code,
  Flex,
  Image,
  Modal,
  Pagination,
  Paper,
  Table,
  Text,
  UnstyledButton
} from '@mantine/core'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { CopyableText } from '@/components/ui-shared/CopyableText/CopyableText'
import { OcrResult } from '@/types/event'
import { getPageCount, getPageOffset } from '@/utils/pagination'

const MAX_THUMB_HEIGHT = 80
const PAGE_SIZE = 3

type OcrResultsProps = {
  results: OcrResult[]
}

export const OcrResults = ({ results }: OcrResultsProps) => {
  const [imageUrl, setImageUrl] = useState('')
  const [page, setPage] = useState(1)

  const pageResults = results
    .map((result) => ({
      ...result,
      uuid: result.ocr_string + '_' + result.ocr_crop_path
    }))
    .slice(getPageOffset(page, PAGE_SIZE), page * PAGE_SIZE)

  const totalPages = getPageCount(results.length, PAGE_SIZE)

  return (
    <>
      <Table horizontalSpacing="xs" verticalSpacing="xs">
        <Table.Tbody>
          {pageResults.map((result) => (
            <Table.Tr key={result.uuid}>
              <Table.Td width="60%">
                <CopyableText text={result.ocr_string} iconSize="sm">
                  <Code
                    color="brand-primary.0"
                    styles={{
                      root: {
                        wordBreak: 'break-all'
                      }
                    }}
                  >
                    {result.ocr_string}
                  </Code>
                </CopyableText>

                <Text size="xs" mt={4} c="dimmed">
                  <FormattedMessage
                    id="events.ocr.confidence"
                    values={{
                      confidence: result.ocr_confidence.toFixed(2)
                    }}
                  />
                </Text>
              </Table.Td>

              <Table.Td>
                <UnstyledButton
                  onClick={() => setImageUrl(result.ocr_crop_path)}
                >
                  <Image
                    h={MAX_THUMB_HEIGHT}
                    fit="contain"
                    src={result.ocr_crop_path}
                  />
                </UnstyledButton>
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>

      {totalPages > 1 && (
        <Flex justify="center" py="md">
          <Pagination
            total={totalPages}
            value={page}
            withPages={false}
            onChange={setPage}
          />
        </Flex>
      )}

      <Modal size="xl" opened={imageUrl !== ''} onClose={() => setImageUrl('')}>
        {imageUrl !== '' && (
          <Paper withBorder>
            <Image h="60vh" fit="contain" src={imageUrl} />
          </Paper>
        )}
      </Modal>
    </>
  )
}
