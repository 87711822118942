import { Card, Paper } from '@mantine/core'
import { useState } from 'react'
import { useHasPermissions } from '@/permissions/useHasPermissions'
import { useSession } from '@/session/SessionContext'
import { CrudAction, Resource } from '@/types/permissions'
import { LicenseInfo } from './LicenseInfo/LicenseInfo'
import { UpdateWorkspaceModal } from './UpdateWorkspaceModal'
import { WorkspaceInfo } from './WorkspaceInfo/WorkspaceInfo'
import { WorkspaceMembers } from './WorkspaceMembers/WorkspaceMembers'

export const WorkspacePage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { activeWorkspaceMembership, user, userSubscription } = useSession()

  const [canUpdateWorkspace] = useHasPermissions(Resource.Workspaces, [
    CrudAction.Update
  ])

  const license = userSubscription?.ordered_items.find(
    (item) => item.product.Product_Category === 'license'
  )

  const onClose = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <Card padding="md" mb="xl" withBorder>
        {activeWorkspaceMembership && (
          <WorkspaceInfo
            workspace={activeWorkspaceMembership.workspace}
            showUpdateButton={canUpdateWorkspace}
            onUpdateClick={() => setIsModalOpen(true)}
          />
        )}

        {userSubscription && license && (
          <Paper mt="lg" p="lg" bg="gray.1">
            <LicenseInfo
              licenseName={license.product.Product_Name}
              price={license.product.Unit_Price}
              currency={license.product.Price_Currency}
              billingPeriod={license.product.Billing_Period}
              status={userSubscription.status}
            />
          </Paper>
        )}
      </Card>

      {activeWorkspaceMembership && (
        <WorkspaceMembers
          workspaceId={activeWorkspaceMembership.workspace.id}
          workspaceName={activeWorkspaceMembership.workspace.name}
          userId={user?.id || ''}
        />
      )}

      {activeWorkspaceMembership && (
        <UpdateWorkspaceModal
          workspace={activeWorkspaceMembership.workspace}
          isOpen={isModalOpen}
          onClose={() => {
            void onClose()
          }}
        />
      )}
    </>
  )
}
