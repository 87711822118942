import { Center, Group, Loader, ThemeIcon, Title } from '@mantine/core'
import { IconBolt } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { useApplicationContext } from '@/providers/ApplicationContext'
import { useModelContext } from '@/providers/ModelContext'
import { MLModel } from '@/types/model'
import { PageWithFixedToolbar } from '../layout/PageWithFixedToolbar/PageWithFixedToolbar'
import { TrainingDetails } from './TrainingDetails/TrainingDetails'

export const TrainingDetailsPage = () => {
  const { application } = useApplicationContext()
  const { model, isFetching } = useModelContext()
  const { training: trainingId } = model || {}

  return (
    <PageWithFixedToolbar
      toolbar={
        <Group align="center" wrap="nowrap" gap="xs">
          <ThemeIcon variant="transparent" size="md">
            <IconBolt style={{ width: '100%', height: '100%' }} />
          </ThemeIcon>

          <Title order={3}>
            <FormattedMessage id="models.stepper.step4" />
          </Title>
        </Group>
      }
    >
      {isFetching && (
        <Center>
          <Loader />
        </Center>
      )}

      {trainingId && (
        <TrainingDetails
          appId={application?.id || ''}
          trainingId={trainingId}
          model={model as MLModel}
        />
      )}
    </PageWithFixedToolbar>
  )
}
