import { Badge, Button, Group, Text } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { Workspace, WorkspaceType } from '@/types/workspace'

type WorkspaceInfoProps = {
  workspace: Workspace
  showUpdateButton?: boolean
  onUpdateClick: () => void
}

export const WorkspaceInfo = ({
  workspace,
  showUpdateButton,
  onUpdateClick
}: WorkspaceInfoProps) => {
  const workspaceTypeStrId =
    workspace.workspace_type === WorkspaceType.Organization
      ? 'workspace.type.organization'
      : 'workspace.type.personal'

  return (
    <Group justify="space-between">
      <Group wrap="nowrap">
        <Text size="md" fw={600}>
          {workspace.name}
        </Text>

        <Badge size="xs" radius="xl" variant="outline">
          <FormattedMessage id={workspaceTypeStrId} />
        </Badge>
      </Group>

      {showUpdateButton && (
        <Button size="xs" onClick={onUpdateClick}>
          <FormattedMessage id="workspace.update" />
        </Button>
      )}
    </Group>
  )
}
