import { Badge, Group, Stack, Text } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { LicenseProduct, SubscriptionStatus } from '@/types/subscription'
import { formatPrice } from '@/utils/price'

type LicenseStatusProps = {
  status: SubscriptionStatus
}

const LicenseStatus = ({ status }: LicenseStatusProps) => {
  if (status === SubscriptionStatus.Active) {
    return (
      <Badge size="xs" color="green">
        <FormattedMessage id="subscriptions.status.active" />
      </Badge>
    )
  }

  return null
}

type LicenseInfoProps = {
  licenseName: string
  price: number
  currency: string
  billingPeriod: LicenseProduct['Billing_Period']
  status: SubscriptionStatus
}

export const LicenseInfo = ({
  licenseName,
  price,
  currency,
  billingPeriod,
  status
}: LicenseInfoProps) => {
  return (
    <Stack gap={4}>
      <Group align="center" wrap="nowrap" gap="xs">
        <Text size="sm" fw={500}>
          {licenseName}
        </Text>

        <LicenseStatus status={status} />
      </Group>

      <Text fw={700}>
        <FormattedMessage
          id={
            billingPeriod === 'monthly'
              ? 'subscriptions.pricePerMonth'
              : 'subscriptions.pricePerYear'
          }
          values={{
            price: formatPrice(price, currency)
          }}
        />
      </Text>
    </Stack>
  )
}
