import { ModalsProvider } from '@mantine/modals'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { BrowserRouter as Router } from 'react-router'
import { CsrfHandler } from './components/csrf/CsrfHandler'
import { ErrorBoundary } from './components/errors/ErrorBoundary'
import { ErrorTester } from './components/errors/ErrorTester'
import { PlatformAnnouncements } from './components/platform-announcements/PlatformAnnouncements'
import { REACT_QUERY_DEVTOOLS_ENABLED } from './config/main'
import { I18nProvider } from './i18n/I18nProvider'
import { Routes } from './router/Routes'
import { SessionProvider } from './session/SessionContext'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false // disable refetch on window focus globally, can be overridden in individual queries
    }
  }
})

export const App = () => {
  const showReactQueryDevtools = REACT_QUERY_DEVTOOLS_ENABLED === 'true'

  return (
    <Router>
      <I18nProvider>
        <ModalsProvider>
          <ErrorBoundary>
            <QueryClientProvider client={queryClient}>
              {showReactQueryDevtools && <ReactQueryDevtools />}
              <CsrfHandler>
                <ErrorTester>
                  <SessionProvider>
                    <Routes />
                    <PlatformAnnouncements />
                  </SessionProvider>
                </ErrorTester>
              </CsrfHandler>
            </QueryClientProvider>
          </ErrorBoundary>
        </ModalsProvider>
      </I18nProvider>
    </Router>
  )
}
