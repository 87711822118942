// Extracts the ROI name from a detection string.
// The detection string is expected to be in the format 'ROI->0->area2->person'.
export const extractRoiName = (detections: string) => {
  const parts = detections.split('->')
  return parts[2] || 'n/a'
}

export const extractObjectName = (detections: string) => {
  const parts = detections.split('->')
  return parts[3] || 'n/a'
}
