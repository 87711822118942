import { LabelingType } from '@/types/dataset'
import { MLModel, MLModelType } from '@/types/model'
import { PaginatedResponse, WithListParams } from '@/types/pagination'
import { vpApi } from './vpApi'

export type MlModelListParams = WithListParams<{
  is_master_model?: boolean
  is_library?: boolean
  labeling_type?: LabelingType
}>

export const getMlModelList = async (params: MlModelListParams) => {
  const res = await vpApi.get<PaginatedResponse<MLModel[]>>(
    `/v1/applications/models/`,
    {
      params
    }
  )

  return res.data
}

const getModelDetails = async (modelId: string) => {
  const res = await vpApi.get<MLModel>(`/v1/applications/models/${modelId}/`)
  return res.data
}

type CreateModelData = {
  application_id: string
  name: string
  model_type: MLModelType
}

const createModel = (data: CreateModelData) => {
  return vpApi.post<MLModel>(`/v1/applications/models/`, data)
}

type UpdateModelParams = {
  modelId: string
  data: {
    base_model_id?: string
    name?: string
    dataset_version_id?: string
  }
}

const updateModel = ({ modelId, data }: UpdateModelParams) => {
  return vpApi.patch(`/v1/applications/models/${modelId}/`, data)
}

const deleteModel = (modelId: string) => {
  return vpApi.delete(`/v1/applications/models/${modelId}/`)
}

export const modelApi = {
  getMlModelList,
  getModelDetails,
  createModel,
  updateModel,
  deleteModel
}
