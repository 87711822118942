import { Button, Group, Stack, Title } from '@mantine/core'
import { IconUserPlus } from '@tabler/icons-react'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHasPermissions } from '@/permissions/useHasPermissions'
import { CrudAction, Resource } from '@/types/permissions'
import { InviteMembersModal } from '../InviteMembers/InviteMembersModal'
import { MembersTable } from './MembersTable'

type WorkspaceMembersProps = {
  workspaceId: string
  workspaceName: string
  userId: string
}

export const WorkspaceMembers = ({
  workspaceId,
  workspaceName,
  userId
}: WorkspaceMembersProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [canInviteMembers] = useHasPermissions(Resource.WorkspaceUsers, [
    CrudAction.Create
  ])

  return (
    <>
      <Stack gap="md">
        <Group justify="space-between" gap="sm">
          <Title order={3}>
            <FormattedMessage id="workspace.members" />
          </Title>

          {canInviteMembers && (
            <Button
              leftSection={<IconUserPlus size={14} />}
              size="xs"
              onClick={() => {
                setIsModalOpen(true)
              }}
            >
              <FormattedMessage id="workspace.members.invite" />
            </Button>
          )}
        </Group>

        <MembersTable
          workspaceName={workspaceName}
          workspaceId={workspaceId}
          userId={userId}
        />
      </Stack>

      <InviteMembersModal
        workspaceId={workspaceId}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  )
}
