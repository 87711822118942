import {
  Anchor,
  Badge,
  Box,
  Checkbox,
  Group,
  Paper,
  Popover,
  Stack,
  Text,
  ThemeIcon,
  ThemeIconProps
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { IconTextRecognition } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { LabelMultiSelect } from '@/components/labels/LabelMultiSelect/LabelMultiSelect'
import { PlatformAlert } from '@/components/ui-shared/PlatformAlert/PlatformAlert'
import { Label } from '@/types/label'
import {
  LabelGroupPopover,
  LabelGroupPopoverButtons,
  LabelGroupPopoverHeader
} from '../LabelGroupPopover/LabelGroupPopover'

export type OcrConfigValues = {
  isOcrEnabled: boolean
  ocrLabelIds: string[]
}

type OcrIconProps = {
  size?: ThemeIconProps['size']
}

const OcrIcon = ({ size = 'sm' }: OcrIconProps) => {
  return (
    <ThemeIcon variant="transparent" color="brand-primary" size={size}>
      <IconTextRecognition style={{ width: '80%', height: '80%' }} stroke={3} />
    </ThemeIcon>
  )
}

type OcrConfigProps = {
  ocrConfig: OcrConfigValues
  labels: Label[]
  disabled?: boolean
  onChange: (config: OcrConfigValues) => void
}

export const OcrConfig = ({
  ocrConfig,
  labels,
  disabled,
  onChange
}: OcrConfigProps) => {
  const intl = useIntl()

  const [isPopoverOpened, setIsPopoverOpened] = useState(false)

  const form = useForm<OcrConfigValues>({
    initialValues: {
      ...ocrConfig
    },
    validate: {
      ocrLabelIds: (value, values) => {
        if (values.isOcrEnabled && value.length === 0) {
          return intl.formatMessage({ id: 'logic.ocr.select.required' })
        }

        return null
      }
    }
  })

  useEffect(() => {
    form.setValues(ocrConfig)
    // do not add form as effect dependency https://github.com/mantinedev/mantine/issues/5338#issuecomment-1837468066
  }, [ocrConfig]) // eslint-disable-line react-hooks/exhaustive-deps

  const options = labels.map((label) => ({
    labelId: label.id,
    name: label.name,
    color: label.color
  }))

  const resetAndClose = () => {
    form.setValues(ocrConfig)
    setIsPopoverOpened(false)
  }

  const handleSave = form.onSubmit((values) => {
    onChange({
      ...values,
      ocrLabelIds: values.isOcrEnabled ? values.ocrLabelIds : []
    })

    setIsPopoverOpened(false)
  })

  const handlePopoverChange = (opened: boolean) => {
    if (!opened) {
      resetAndClose()
    } else {
      setIsPopoverOpened(true)
    }
  }

  const handleOcrEnabledChange = (enabled: boolean) => {
    form.setFieldValue('isOcrEnabled', enabled)

    if (enabled && form.getValues().ocrLabelIds.length === 0) {
      // preselect all labels if OCR is enabled and none are selected
      form.setFieldValue(
        'ocrLabelIds',
        labels.map((label) => label.id)
      )
    }
  }

  return (
    <LabelGroupPopover opened={isPopoverOpened} onChange={handlePopoverChange}>
      <Popover.Target>
        <Anchor
          component="button"
          type="button"
          disabled={disabled}
          size="xs"
          styles={{
            root: {
              textTransform: 'lowercase',
              cursor: disabled ? 'not-allowed' : 'pointer'
            }
          }}
          onClick={() => handlePopoverChange(!isPopoverOpened)}
        >
          <Group wrap="nowrap" gap={4} align="center">
            <OcrIcon size="xs" />

            <FormattedMessage id="logic.ocr.title" />

            {!ocrConfig.isOcrEnabled && (
              <Badge size="xs">
                <FormattedMessage id={'logic.ocr.no'} />
              </Badge>
            )}

            {ocrConfig.isOcrEnabled &&
              ocrConfig.ocrLabelIds.map((labelId) => {
                const label = labels.find((l) => l.id === labelId)

                return label ? (
                  <Badge key={labelId} size="xs" color={label.color}>
                    {label.name}
                  </Badge>
                ) : null
              })}
          </Group>
        </Anchor>
      </Popover.Target>

      <Popover.Dropdown>
        <LabelGroupPopoverHeader
          title={<FormattedMessage id="logic.ocr.title" />}
          icon={<OcrIcon size="sm" />}
          onClose={resetAndClose}
        />
        <PlatformAlert showIcon={false} variant="info" mb="md" compact>
          <Stack>
            <Text size="xs">
              <FormattedMessage id="logic.ocr.description1" />
            </Text>

            <Text size="xs">
              <FormattedMessage id="logic.ocr.description2" />
            </Text>
          </Stack>
        </PlatformAlert>

        <Paper bg="gray.1" p="sm">
          <Checkbox
            checked={form.getValues().isOcrEnabled}
            label={<FormattedMessage id="logic.ocr.checkbox.label" />}
            onChange={(event) =>
              handleOcrEnabledChange(event.currentTarget.checked)
            }
          />

          {form.getValues().isOcrEnabled && (
            <Box mt="xl">
              <LabelMultiSelect
                values={form.getValues().ocrLabelIds}
                options={options}
                label={<FormattedMessage id="logic.ocr.select.label" />}
                error={form.errors.ocrLabelIds}
                withinPortal={false}
                onChange={(value) => form.setFieldValue('ocrLabelIds', value)}
              />
            </Box>
          )}
        </Paper>

        <LabelGroupPopoverButtons
          onCancel={resetAndClose}
          onSave={handleSave}
        />
      </Popover.Dropdown>
    </LabelGroupPopover>
  )
}
