import { Button, Center, Stack } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { PlatformAlert } from '@/components/ui-shared/PlatformAlert/PlatformAlert'

type LinkExpiredProps = {
  onResendEmail: () => void
}

export const LinkExpired = ({ onResendEmail }: LinkExpiredProps) => {
  return (
    <Stack>
      <PlatformAlert variant="warning" compact>
        <FormattedMessage id="inviteEmail.expired" />
      </PlatformAlert>

      <Center>
        <Button size="xs" onClick={onResendEmail}>
          <FormattedMessage id="inviteEmail.resend" />
        </Button>
      </Center>
    </Stack>
  )
}
