import { NavLink as MantineNavLink, rem } from '@mantine/core'
import { IconBox, IconFolders, IconSchool } from '@tabler/icons-react'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link, useLocation } from 'react-router'
import { RouterPath } from '@/router/paths'

type NavLinkProps = {
  to: string
  icon: ReactNode
  label: string | ReactNode
}

const NavLink = ({ to, icon, label }: NavLinkProps) => {
  const { pathname } = useLocation()

  return (
    <MantineNavLink
      component={Link}
      to={to}
      label={label}
      leftSection={icon}
      variant="light"
      color="brand-secondary"
      styles={{
        label: {
          fontSize: rem(15),
          fontWeight: 600
        }
      }}
      active={pathname.includes(to)}
    />
  )
}

export const HomeNavLinks = () => {
  return (
    <>
      <NavLink
        to={RouterPath.apps}
        icon={<IconBox size={18} />}
        label={<FormattedMessage id="navLinks.applications" />}
      />

      <NavLink
        to={RouterPath.datasets}
        icon={<IconFolders size={18} />}
        label={<FormattedMessage id="navLinks.datasets" />}
      />

      <NavLink
        to={RouterPath.learning}
        icon={<IconSchool size={18} />}
        label={<FormattedMessage id="navLinks.learning" />}
      />
    </>
  )
}
