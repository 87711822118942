import { Badge, ComboboxItem, Select, SelectProps } from '@mantine/core'
import { cloneElement, forwardRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  LABELING_TYPES,
  LABELING_TYPES_CONFIG_MAP
} from '@/components/annotations/LabelingTypes/LabelingTypeConfig'
import { LabelingType } from '@/types/dataset'
import { SelectOptionWithCheckbox } from './SelectOptionWithCheckbox'

export const LabelingTypeSelect = forwardRef<HTMLInputElement, SelectProps>(
  (props, ref) => {
    const intl = useIntl()

    const labelingTypeOptions = LABELING_TYPES.map((type) => ({
      type,
      value: String(type),
      label: intl.formatMessage({
        id: LABELING_TYPES_CONFIG_MAP[type].name
      }),
      disabled: LABELING_TYPES_CONFIG_MAP[type].disabled
    }))

    return (
      <Select
        ref={ref}
        label={<FormattedMessage id="annotation.type" />}
        maxDropdownHeight={260}
        allowDeselect={false}
        data={labelingTypeOptions}
        renderOption={({ option, checked }) => {
          const { type } = option as ComboboxItem & { type: LabelingType }
          const config = LABELING_TYPES_CONFIG_MAP[type]

          return (
            <SelectOptionWithCheckbox
              icon={cloneElement(config.icon, { size: 20 })}
              name={<FormattedMessage id={config.name} />}
              description={<FormattedMessage id={config.description} />}
              isChecked={checked || false}
              badge={
                option.disabled && (
                  <Badge size="xs" color="brand-secondary">
                    <FormattedMessage id="general.badges.comingSoon" />
                  </Badge>
                )
              }
            />
          )
        }}
        {...props}
      />
    )
  }
)

LabelingTypeSelect.displayName = 'LabelingTypeSelect'
