import {
  Badge,
  Button,
  Flex,
  Group,
  Table,
  Text,
  ThemeIcon
} from '@mantine/core'
import { IconCheck, IconRefresh } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { LabelingTypeChip } from '@/components/annotations/LabelingTypes/LabelingTypeChip'
import { LabelGroup } from '@/components/labels/LabelGroup/LabelGroup'
import { SortableLabel } from '@/components/ui-shared/Tables/SortableLabel'
import { MLModel, MLModelType } from '@/types/model'
import { SortConfig } from '@/utils/sorting'
import { ModelTypeChip } from '../ModelTypeChip/ModelTypeChip'
import { LibraryModelMoreInfo } from './LibraryModelMoreInfo'

export type TableSortConfig = SortConfig<'name'>

type LibraryModelListProps = {
  libraryModels: MLModel[]
  selectedModelId?: string
  sortConfig: TableSortConfig
  canTrainFromScratch?: boolean
  onSort: (sortConfig: TableSortConfig) => void
  onTest: (modelId: string) => void
  onRetrain: (modelId: string) => void
  onDeploy: (modelId: string) => void
  onTrainFromScratch: () => void
}

export const LibraryModelList = ({
  libraryModels,
  selectedModelId,
  sortConfig,
  canTrainFromScratch,
  onSort,
  onTest,
  onRetrain,
  onDeploy,
  onTrainFromScratch
}: LibraryModelListProps) => {
  return (
    <Table
      horizontalSpacing="xs"
      verticalSpacing="xs"
      highlightOnHoverColor="gray.0"
      withTableBorder
      highlightOnHover
    >
      <Table.Thead>
        <Table.Tr>
          <Table.Th>
            <SortableLabel
              label={<FormattedMessage id="models.library.list.columns.name" />}
              sortKey="name"
              sortConfig={sortConfig}
              onSort={onSort}
            />
          </Table.Th>

          <Table.Th>
            <FormattedMessage id="models.library.list.columns.type" />
          </Table.Th>

          <Table.Th w={360}>
            <FormattedMessage id="models.library.list.columns.labelingClasses" />
          </Table.Th>

          <Table.Th ta="right">
            {canTrainFromScratch && (
              <Button
                size="sm"
                leftSection={<IconRefresh size={16} />}
                onClick={onTrainFromScratch}
              >
                <FormattedMessage id="models.library.list.buttons.fromScratch" />
              </Button>
            )}
          </Table.Th>
        </Table.Tr>
      </Table.Thead>

      <Table.Tbody>
        {libraryModels.map((libraryModel) => (
          <Table.Tr
            key={libraryModel.id}
            style={
              libraryModel.id === selectedModelId
                ? {
                    outline: '1px solid var(--mantine-color-green-6)',
                    backgroundColor: 'var(--mantine-color-green-0)'
                  }
                : {}
            }
          >
            <Table.Td>
              <Group wrap="nowrap" gap="xs">
                {libraryModel.id === selectedModelId && (
                  <ThemeIcon color="green" radius="xl" size={16}>
                    <IconCheck style={{ width: '80%', height: '80%' }} />
                  </ThemeIcon>
                )}

                <Text size="sm" fw={700}>
                  {libraryModel.name}
                </Text>

                {libraryModel.is_beta && (
                  <Badge color="brand-primary" size="xs">
                    <FormattedMessage id="general.badges.beta" />
                  </Badge>
                )}

                <LibraryModelMoreInfo libraryModel={libraryModel} />
              </Group>
            </Table.Td>

            <Table.Td>
              <Group gap="xs">
                {libraryModel.labeling_type && (
                  <LabelingTypeChip labelingType={libraryModel.labeling_type} />
                )}

                {libraryModel.model_type === MLModelType.Secondary && (
                  <ModelTypeChip modelType={libraryModel.model_type} />
                )}
              </Group>
            </Table.Td>

            <Table.Td>
              <Flex mih={48} direction="column" justify="center">
                <LabelGroup
                  labels={libraryModel.dataset_version?.labels || []}
                  maxLabelsShown={6}
                />
              </Flex>
            </Table.Td>

            <Table.Td>
              <Group justify="end">
                <Button
                  size="compact-xs"
                  miw={80}
                  color="blue"
                  disabled={!libraryModel.can_be_tested}
                  onClick={() => onTest(libraryModel.id)}
                >
                  <FormattedMessage id="models.library.list.buttons.test" />
                </Button>

                <Button
                  size="compact-xs"
                  miw={80}
                  color="orange"
                  disabled={!libraryModel.can_be_trained}
                  onClick={() => onRetrain(libraryModel.id)}
                >
                  <FormattedMessage id="models.library.list.buttons.retrain" />
                </Button>

                <Button
                  size="compact-xs"
                  miw={80}
                  color="green"
                  disabled={!libraryModel.can_be_deployed}
                  onClick={() => onDeploy(libraryModel.id)}
                >
                  <FormattedMessage id="models.library.list.buttons.deploy" />
                </Button>
              </Group>
            </Table.Td>
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  )
}
