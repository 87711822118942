import { Card, Center, Image, Stack, Text } from '@mantine/core'
import VpLogoSrc from '@/assets/vp-stripes.png'
import { ModelStatus } from '@/components/models/ModelStatus/ModelStatus'
import { MLModelStatus } from '@/types/model'
import { SelectableCard } from '../../../../ui-shared/SelectableCard/SelectableCard'

type SelectableModelCardProps = {
  name: string
  status: MLModelStatus
  logoUrl?: string
  isSelected?: boolean
  onClick?: () => void
}

export const SelectableModelCard = ({
  name,
  status,
  logoUrl,
  isSelected,
  onClick
}: SelectableModelCardProps) => {
  return (
    <SelectableCard height={175} isSelected={isSelected} onClick={onClick}>
      <Card.Section withBorder>
        <Center h={95} bg="gray.0">
          <Image src={logoUrl || VpLogoSrc} h={32} w="auto" />
        </Center>
      </Card.Section>

      <Card.Section py="sm" inheritPadding>
        <Stack align="center" gap={4}>
          <Text w="100%" ta="center" size="xs" fw="bold" lineClamp={2}>
            {name}
          </Text>

          <ModelStatus status={status} />
        </Stack>
      </Card.Section>
    </SelectableCard>
  )
}
