import {
  Card,
  Checkbox,
  Group,
  ScrollArea,
  SimpleGrid,
  Stack,
  Text
} from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { ImportCameraData } from '@/types/device'
import { CameraSelectionCard } from './CameraSelectionCard'

type CameraSelectionListProps = {
  selectedCameras: ImportCameraData[]
  camerasToImport: ImportCameraData[]
  alreadyImportedCameras: ImportCameraData[]
  onSelectionChange: (selectedCameras: ImportCameraData[]) => void
}

export const CameraSelectionList = ({
  selectedCameras,
  camerasToImport,
  alreadyImportedCameras,
  onSelectionChange
}: CameraSelectionListProps) => {
  const isSelected = (camera: ImportCameraData) => {
    return selectedCameras.some(
      (selectedCamera) => selectedCamera.id === camera.id
    )
  }

  const handleToggleSelection = (camera: ImportCameraData) => {
    const newSelectedCameras = isSelected(camera)
      ? selectedCameras.filter(
          (selectedCamera) => selectedCamera.id !== camera.id
        )
      : [...selectedCameras, camera]

    onSelectionChange(newSelectedCameras)
  }

  const handleSelectAll = (checked: boolean) => {
    const newSelectedCameras = checked ? camerasToImport : []

    onSelectionChange(newSelectedCameras)
  }

  return (
    <>
      <Group justify="space-between" align="end" mb="sm">
        <Text size="sm">
          <FormattedMessage id="streams.import.select" />
        </Text>

        <Checkbox
          checked={
            camerasToImport.length > 0 &&
            selectedCameras.length === camerasToImport.length
          }
          label={<FormattedMessage id="selectAll" />}
          onChange={(e) => handleSelectAll(e.target.checked)}
        />
      </Group>

      <ScrollArea h={400}>
        <Card mih={400} withBorder>
          <SimpleGrid cols={{ base: 4 }} spacing="md">
            {camerasToImport.map((camera) => (
              <CameraSelectionCard
                key={camera.id}
                isSelected={isSelected(camera)}
                name={camera.name}
                thumbnail={camera.rtsp.thumbnail || undefined}
                onClick={() => handleToggleSelection(camera)}
              />
            ))}
          </SimpleGrid>

          {alreadyImportedCameras.length > 0 && (
            <Stack mt="xl" gap="xs">
              <Text size="xs" fw="bold">
                <FormattedMessage id="import.alreadyImported" />
              </Text>

              <SimpleGrid cols={{ base: 4 }} spacing="md">
                {alreadyImportedCameras.map((camera) => (
                  <CameraSelectionCard
                    key={camera.id}
                    isSelected={isSelected(camera)}
                    name={camera.name}
                    thumbnail={camera.rtsp.thumbnail || undefined}
                    disabled
                  />
                ))}
              </SimpleGrid>
            </Stack>
          )}
        </Card>
      </ScrollArea>
    </>
  )
}
