import { Badge, Group, HoverCard } from '@mantine/core'
import { useMemo } from 'react'
import { Label } from '@/types/label'

const BADGE_COLOR = 'blue.8'

type LabelGroupProps = {
  labels: Label[]
  maxLabelsShown?: number
}

export const LabelGroup = ({ labels, maxLabelsShown }: LabelGroupProps) => {
  const sortedLabels = useMemo(() => {
    return [...labels].sort((a, b) => a.name.localeCompare(b.name))
  }, [labels])

  const labelCount = sortedLabels.length

  return (
    <Group gap={6}>
      {sortedLabels.slice(0, maxLabelsShown).map((item) => (
        <Badge key={item.name} size="xs" color={BADGE_COLOR}>
          {item.name}
        </Badge>
      ))}

      {maxLabelsShown && labelCount > maxLabelsShown && (
        <HoverCard width={360} shadow="md">
          <HoverCard.Target>
            <Badge size="sm" variant="light">
              + {labelCount - maxLabelsShown}
            </Badge>
          </HoverCard.Target>

          <HoverCard.Dropdown>
            <Group gap={6}>
              {sortedLabels.slice(maxLabelsShown).map((item) => (
                <Badge key={item.name} size="xs" color={BADGE_COLOR}>
                  {item.name}
                </Badge>
              ))}
            </Group>
          </HoverCard.Dropdown>
        </HoverCard>
      )}
    </Group>
  )
}
