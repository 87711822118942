import { Checkbox, Group, Stack, Tooltip } from '@mantine/core'
import { FormattedMessage } from 'react-intl'

type EventSettingType =
  | 'notification'
  | 'upload_video'
  | 'upload_image'
  | 'upload_bbox_video'

export type EventSettingsValue = {
  [key in EventSettingType]: boolean
}

type EventSettingsProps = {
  settings: EventSettingsValue
  showNotification?: boolean
  showVideoUpload?: boolean
  showImageUpload?: boolean
  onChange: (settings: EventSettingsValue) => void
}

export const EventSettings = ({
  settings,
  showImageUpload = false,
  showNotification = false,
  showVideoUpload = false,
  onChange
}: EventSettingsProps) => {
  return (
    <Stack gap="xs">
      {showNotification && (
        <Checkbox
          size="xs"
          label={<FormattedMessage id="logic.eventSettings.sendEmail" />}
          checked={settings.notification}
          onChange={(event) =>
            onChange({ ...settings, notification: event.currentTarget.checked })
          }
        />
      )}

      {showImageUpload && (
        <Checkbox
          size="xs"
          label={<FormattedMessage id="logic.eventSettings.uploadImage" />}
          checked={settings.upload_image}
          onChange={(event) =>
            onChange({ ...settings, upload_image: event.currentTarget.checked })
          }
        />
      )}

      {showVideoUpload && (
        <Group gap="xl">
          <Tooltip
            w={300}
            label={
              <FormattedMessage id="logic.eventSettings.uploadVideo.disabled" />
            }
            multiline
          >
            <Checkbox
              size="xs"
              label={<FormattedMessage id="logic.eventSettings.uploadVideo" />}
              checked={settings.upload_video}
              disabled // TODO: remove this after ML fix is implemented VP-3262
              onChange={(event) => {
                const value = event.currentTarget.checked

                onChange({
                  ...settings,
                  upload_video: value,
                  upload_bbox_video: value ? settings.upload_bbox_video : false
                })
              }}
            />
          </Tooltip>

          {settings.upload_video && (
            <Tooltip
              w={300}
              label={
                <FormattedMessage id="logic.eventSettings.labeledVideo.tooltip" />
              }
              multiline
            >
              <div>
                <Checkbox
                  size="xs"
                  label={
                    <FormattedMessage id="logic.eventSettings.labeledVideo" />
                  }
                  checked={settings.upload_bbox_video}
                  onChange={(event) =>
                    onChange({
                      ...settings,
                      upload_bbox_video: event.currentTarget.checked
                    })
                  }
                />
              </div>
            </Tooltip>
          )}
        </Group>
      )}
    </Stack>
  )
}
