import { Anchor, Text } from '@mantine/core'
import { FormattedMessage } from 'react-intl'

type PasswordResetConfirmationProps = {
  onTryAgainClick: () => void
}

export const PasswordResetConfirmation = ({
  onTryAgainClick
}: PasswordResetConfirmationProps) => {
  return (
    <>
      <Text size="sm" ta="center" mb="xl">
        <FormattedMessage id="passwordReset.emailWithInstructions" />
      </Text>

      <Text size="sm" ta="center">
        <FormattedMessage
          id="passwordReset.didNotReceiveEmail"
          values={{
            link: (chunks) => (
              <Anchor underline="always" onClick={onTryAgainClick}>
                {chunks}
              </Anchor>
            )
          }}
        />
      </Text>
    </>
  )
}
