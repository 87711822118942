import { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import {
  useFetchDownloadEventUrl,
  useGenerateEventsAs
} from '@/queries/eventQueries'
import { usePollTaskStatus } from '@/queries/generalQueries'
import { showToast } from '@/theme/notifications'
import { EventDateRange } from '@/types/event'

export enum DownloadType {
  PDF = 1,
  CSV = 2
}

export type SelectedEventFiltersType = {
  event_ids?: string[]
  date_range?: EventDateRange
  download_type: DownloadType | null
}

const getSelectedEventData = (
  params: DownloadAsParamsType
): SelectedEventFiltersType => {
  const { eventIds, dateRange, download_type } = params

  if (eventIds) {
    return { event_ids: eventIds, download_type }
  } else if (dateRange) {
    return { date_range: dateRange, download_type }
  } else {
    return { download_type }
  }
}

export type DownloadAsParamsType = {
  eventIds?: string[]
  dateRange?: EventDateRange
  download_type: DownloadType | null
}

type UseDownloadAsType = {
  appId: string
}

export const useDownloadAs = ({ appId }: UseDownloadAsType) => {
  const intl = useIntl()

  const [downloadTaskId, setDownloadTaskId] = useState('')

  const { mutateAsync: downloadAs, isPending } = useGenerateEventsAs()

  const { data: downloadTaskResult } = usePollTaskStatus(
    downloadTaskId,
    downloadTaskId !== ''
  )

  const { data: fetchEventResult, isError: isEventError } =
    useFetchDownloadEventUrl(
      appId || '',
      downloadTaskId || '',
      downloadTaskResult?.task_status === 'SUCCESS'
    )

  const handleDownloadAs = useCallback(
    async (params: DownloadAsParamsType) => {
      showToast(
        intl.formatMessage({ id: 'events.download.generatingFile' }),
        'info'
      )

      try {
        const data = getSelectedEventData(params)
        const response = await downloadAs({ appId, data })

        setDownloadTaskId(response.data.task_id)
      } catch (err) {
        const { errorMessage } = getApiError(err)

        const message =
          errorMessage ||
          intl.formatMessage({
            id: 'events.download.error'
          })

        showToast(message, 'error')
      }
    },
    [appId, downloadAs, intl]
  )

  useEffect(() => {
    if (downloadTaskResult?.task_status === 'SUCCESS' && fetchEventResult) {
      const fileUrl = fetchEventResult.data.url

      const link = document.createElement('a')
      link.href = fileUrl
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      showToast(
        intl.formatMessage({ id: 'events.download.success' }),
        'success'
      )
    } else if (downloadTaskResult?.task_status === 'FAILURE') {
      showToast(
        intl.formatMessage({ id: 'events.download.noEventsInDateRange' }),
        'info'
      )
    } else if (isEventError) {
      showToast(
        intl.formatMessage({
          id: 'events.download.error'
        }),
        'error'
      )
    }
  }, [downloadTaskId, downloadTaskResult, intl, fetchEventResult, isEventError])

  return {
    downloadTaskId,
    isDownloadPending:
      isPending || downloadTaskResult?.task_status === 'PENDING',
    handleDownloadAs
  }
}
