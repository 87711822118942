import { ActionIcon, ActionIconProps, Group, Tooltip } from '@mantine/core'
import { useClipboard } from '@mantine/hooks'
import { IconCopy } from '@tabler/icons-react'
import { PropsWithChildren } from 'react'
import { FormattedMessage } from 'react-intl'

type CopyableTextProps = PropsWithChildren<{
  text: string
  iconSize?: ActionIconProps['size']
  timeout?: number
}>

export const CopyableText = ({
  text,
  iconSize = 'sm',
  timeout = 2000,
  children
}: CopyableTextProps) => {
  const clipboard = useClipboard({ timeout })

  return (
    <Group wrap="nowrap" gap={4}>
      <div>{children}</div>

      <Tooltip
        label={
          clipboard.copied ? (
            <FormattedMessage id="general.clipboard.copied" />
          ) : (
            <FormattedMessage id="general.clipboard.copy" />
          )
        }
      >
        <ActionIcon
          variant="subtle"
          size={iconSize}
          onClick={() => clipboard.copy(text)}
        >
          <IconCopy style={{ width: '80%', height: '80%' }} />
        </ActionIcon>
      </Tooltip>
    </Group>
  )
}
