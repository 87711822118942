import { ActionIcon, Group, Text, Tooltip } from '@mantine/core'
import { IconFolder, IconPencil, IconTrash } from '@tabler/icons-react'
import { FormattedMessage, useIntl } from 'react-intl'
import { LabelGroup } from '@/components/labels/LabelGroup/LabelGroup'
import { DatasetVersion } from '@/types/dataset'
import { Label } from '@/types/label'
import { DatasetVersionSelect } from '../DatasetVersionSelect/DatasetVersionSelect'

type DatasetToolbarProps = {
  datasetName: string
  labels: Label[]
  versions: DatasetVersion[]
  activeVersionId?: string
  isVersionPending?: boolean
  isVersionCompleted?: boolean
  showDeleteVersionButton?: boolean
  showEditLabelsButton?: boolean
  onVersionChange: (versionId: string) => void
  onVersionDelete: (versionId: string) => void
  onEditLabels: () => void
}

export const DatasetToolbar = ({
  datasetName,
  labels,
  versions,
  activeVersionId,
  isVersionPending,
  isVersionCompleted,
  showDeleteVersionButton,
  showEditLabelsButton,
  onVersionChange,
  onVersionDelete,
  onEditLabels
}: DatasetToolbarProps) => {
  const intl = useIntl()

  const versionSelectOptions = versions.map((version) => ({
    value: version.id,
    label: intl
      .formatMessage(
        { id: 'datasets.versionNumber' },
        { number: version.version }
      )
      .toLowerCase(),
    version
  }))

  return (
    <Group align="center" justify="space-between" wrap="nowrap" gap="lg">
      <Group wrap="nowrap" align="center" gap="lg">
        <Group wrap="nowrap" gap="xs">
          <IconFolder size={20} />

          <Text size="md" fw={600} miw={100} maw={300} truncate>
            {datasetName}
          </Text>
        </Group>

        <Group miw={180} wrap="nowrap" gap="xs">
          <DatasetVersionSelect
            value={activeVersionId}
            versions={versionSelectOptions}
            isPending={isVersionPending}
            onChange={onVersionChange}
          />

          {/* last version cannot be removed */}
          {versions.length > 1 &&
            activeVersionId &&
            showDeleteVersionButton && (
              <Tooltip
                label={
                  <FormattedMessage
                    id={
                      isVersionCompleted
                        ? 'datasets.version.delete.restricted'
                        : 'datasets.version.delete'
                    }
                  />
                }
              >
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  radius="xl"
                  disabled={isVersionCompleted}
                  onClick={() => onVersionDelete(activeVersionId)}
                >
                  <IconTrash style={{ width: '70%', height: '70%' }} />
                </ActionIcon>
              </Tooltip>
            )}
        </Group>
      </Group>

      <Group wrap="nowrap">
        <LabelGroup labels={labels} maxLabelsShown={5} />

        {showEditLabelsButton && (
          <Tooltip label={<FormattedMessage id="annotation.classes.edit" />}>
            <ActionIcon
              variant="subtle"
              size="md"
              radius="xl"
              onClick={onEditLabels}
            >
              <IconPencil style={{ width: '60%', height: '60%' }} />
            </ActionIcon>
          </Tooltip>
        )}
      </Group>
    </Group>
  )
}
