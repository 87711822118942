import { Select, SelectProps } from '@mantine/core'
import { useIntl } from 'react-intl'
import {
  LABELING_TYPES,
  LABELING_TYPES_CONFIG_MAP
} from '@/components/annotations/LabelingTypes/LabelingTypeConfig'
import { LabelingType } from '@/types/dataset'

type LabelingTypeSelect = Omit<SelectProps, 'value' | 'data' | 'onChange'> & {
  value: LabelingType
  onChange?: (value: LabelingType) => void
}

export const LabelingTypeSelect = ({
  value,
  onChange,
  ...props
}: LabelingTypeSelect) => {
  const intl = useIntl()

  const data = LABELING_TYPES.map((labelingType) => ({
    value: String(labelingType),
    label: intl.formatMessage({
      id: LABELING_TYPES_CONFIG_MAP[labelingType].name
    }),
    disabled: LABELING_TYPES_CONFIG_MAP[labelingType].disabled
  }))

  const handleChange = (value: string | null) => {
    if (value && onChange) {
      onChange(Number(value) as LabelingType)
    }
  }

  return (
    <Select
      {...props}
      allowDeselect={false}
      value={String(value)}
      data={data}
      onChange={handleChange}
    />
  )
}
