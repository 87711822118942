import { Avatar, Card, Group, Text, UnstyledButton, rem } from '@mantine/core'
import { IconCheck } from '@tabler/icons-react'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { ModelNestedPath } from '@/router/paths'
import classes from './ModelStepper.module.css'

type StepIconProps = {
  step: number
  isCompleted: boolean
  isActive: boolean
}

const StepIcon = ({ step, isCompleted, isActive }: StepIconProps) => {
  if (isCompleted) {
    return (
      <Avatar size={22} radius="xl" color="green" variant="filled">
        <IconCheck size={12} stroke={3} />
      </Avatar>
    )
  }

  return (
    <Avatar
      size={22}
      radius="xl"
      color={isActive ? 'blue' : 'gray'}
      variant="filled"
      styles={{
        placeholder: {
          fontSize: rem(13)
        }
      }}
    >
      {step}
    </Avatar>
  )
}

type StepProps = {
  step: number
  title: ReactNode
  isCompleted: boolean
  isActive: boolean
  isClickable: boolean
  onClick: () => void
}

const Step = ({
  step,
  title,
  isCompleted,
  isActive,
  isClickable,
  onClick
}: StepProps) => {
  const handleClick = () => {
    if (isClickable) {
      onClick()
    }
  }

  return (
    <li>
      <UnstyledButton
        w="100%"
        style={{
          cursor: isClickable ? 'pointer' : 'not-allowed',
          opacity: isClickable ? 1 : 0.5
        }}
        onClick={handleClick}
      >
        <Card bg={isActive ? 'brand-primary.0' : undefined} p={10}>
          <Group wrap="nowrap" gap="sm">
            <StepIcon
              step={step}
              isCompleted={isCompleted}
              isActive={isActive}
            />

            <Text
              c={isClickable || isActive ? undefined : 'brand-primary.9'}
              fw={500}
              size="sm"
            >
              {title}
            </Text>
          </Group>
        </Card>
      </UnstyledButton>
    </li>
  )
}

export type ModelStep = {
  id: number
  messageId: string
  urlPath: ModelNestedPath
}

type ModelStepperProps = {
  steps: ModelStep[]
  activeStep: number
  completedSteps: number[]
  disabledSteps: number[]
  onStepClick: (step: ModelStep) => void
}

export const ModelStepper = ({
  steps,
  activeStep,
  completedSteps,
  disabledSteps,
  onStepClick
}: ModelStepperProps) => {
  return (
    <ul className={classes['model-stepper']}>
      {steps.map((step, i) => (
        <Step
          key={step.id}
          step={i + 1}
          title={<FormattedMessage id={step.messageId} />}
          isCompleted={completedSteps.includes(i)}
          isActive={activeStep === i}
          isClickable={!disabledSteps.includes(i)}
          onClick={() => onStepClick(step)}
        />
      ))}
    </ul>
  )
}
