// TODO remove after https://github.com/FlowiseAI/FlowiseChatEmbed/issues/105 is resolved
type ChatbotInstance = {
  initFull: (config: {
    chatflowid: string
    apiHost: string
    theme: {
      button: {
        backgroundColor: string
      }
      chatWindow: {
        welcomeMessage: string
        showTitle: boolean
        title: string
        height: number
        fontSize: number
        poweredByTextColor: string
        textInput: {
          autoFocus: boolean
        }
      }
    }
  }) => void
}

export class ChatbotSingleton {
  private static instance: ChatbotInstance | null = null

  private constructor() {}

  public static async getInstance(): Promise<ChatbotInstance> {
    if (!ChatbotSingleton.instance) {
      const { default: Chatbot } = await import('flowise-embed/dist/web')
      ChatbotSingleton.instance = Chatbot
    }

    return ChatbotSingleton.instance
  }
}
