import { accountApi } from '@/api/accountApi'
import { subscriptionApi } from '@/api/subscriptionApi'
import { normalizeApiPermissions } from '@/permissions/normalizeApiPermissions'
import { PermissionsMap } from '@/types/permissions'
import { Subscription, SubscriptionStatus } from '@/types/subscription'
import { User } from '@/types/user'
import { WorkspaceMember } from '@/types/workspace'

export type UserSession = {
  user: User
  subscriptions: Subscription[]
  workspaceMemberships: WorkspaceMember[]
  permissions: PermissionsMap
}

export const getUserSession = async (): Promise<UserSession> => {
  const { user } = await accountApi.whoAmI()

  const [subscriptions, workspaceMemberships] = await Promise.all([
    subscriptionApi.getSubscriptions({
      status: SubscriptionStatus.Active,
      ordering: '-created_at' // newest first
    }),
    accountApi.getMyWorkspaceMemberships()
  ])

  return {
    user,
    subscriptions: subscriptions.results,
    workspaceMemberships,
    permissions: normalizeApiPermissions(user.workspace_permissions)
  }
}
