import { Card, Center, Stack, Text, ThemeIcon, Tooltip } from '@mantine/core'
import { IconCpu2, IconSettings } from '@tabler/icons-react'
import { MouseEvent } from 'react'
import { FormattedMessage } from 'react-intl'
import { SelectableCard } from '../../../../ui-shared/SelectableCard/SelectableCard'
import { OnlineStatus } from '../../../OnlineStatus/OnlineStatus'

type SelectableDeviceCardProps = {
  name: string
  isActive: boolean
  isSelected?: boolean
  disabled?: boolean
  isSettingsShown?: boolean
  onClick?: () => void
  onSettingsClick?: () => void
}

export const SelectableDeviceCard = ({
  name,
  isActive,
  isSelected,
  disabled,
  isSettingsShown,
  onClick,
  onSettingsClick
}: SelectableDeviceCardProps) => {
  const handleSettingsClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    onSettingsClick?.()
  }

  return (
    <SelectableCard
      height={175}
      isSelected={isSelected}
      disabled={disabled}
      onClick={onClick}
    >
      <Card.Section withBorder>
        <Center h={95} bg={isActive ? 'green.0' : 'red.1'}>
          <ThemeIcon size="xl" variant="light" radius="xl">
            <IconCpu2 style={{ width: '70%', height: '70%' }} />
          </ThemeIcon>
        </Center>
      </Card.Section>

      <Card.Section py="sm" pos="relative" inheritPadding>
        <Stack align="center" gap={4}>
          <Text w="100%" ta="center" size="xs" fw="bold" lineClamp={2}>
            {name}
          </Text>

          <OnlineStatus status={isActive ? 'online' : 'offline'} />
        </Stack>

        {isSettingsShown && (
          <Tooltip label={<FormattedMessage id="devices.settings" />}>
            <ThemeIcon
              variant="subtle"
              size="sm"
              pos="absolute"
              top={0}
              right={0}
              onClick={handleSettingsClick}
            >
              <IconSettings style={{ width: '70%', height: '70%' }} />
            </ThemeIcon>
          </Tooltip>
        )}
      </Card.Section>
    </SelectableCard>
  )
}
