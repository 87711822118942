import { Badge } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { MLModelType } from '@/types/model'

const MODEL_TYPE_NAMES = {
  [MLModelType.Primary]: 'models.type.stage1',
  [MLModelType.Secondary]: 'models.type.stage2'
}

type ModelTypeChipProps = {
  modelType: MLModelType
}

export const ModelTypeChip = ({ modelType }: ModelTypeChipProps) => {
  const name = MODEL_TYPE_NAMES[modelType]

  if (!name) {
    return null
  }

  return (
    <Badge variant="outline" color="brand-secondary" size="xs">
      <FormattedMessage id={name} />
    </Badge>
  )
}
