import {
  IconLayersUnion,
  IconSearch,
  IconSquaresFilled,
  IconTag
} from '@tabler/icons-react'
import { LabelingType } from '@/types/dataset'

type LabelingTypeConfigValues = {
  name: string
  description: string
  hint: string
  icon: JSX.Element
  disabled: boolean
}

export const LABELING_TYPES_CONFIG_MAP: Record<
  LabelingType,
  LabelingTypeConfigValues
> = {
  [LabelingType.ObjectDetection]: {
    name: 'annotation.type.objectDetection',
    description: 'annotation.type.objectDetection.description',
    hint: 'annotation.type.objectDetection.example',
    icon: <IconSearch />,
    disabled: false
  },
  [LabelingType.InstanceSegmentation]: {
    name: 'annotation.type.instanceSegmentation',
    description: 'annotation.type.instanceSegmentation.description',
    hint: 'annotation.type.instanceSegmentation.example',
    icon: <IconSquaresFilled />,
    disabled: true
  },
  [LabelingType.SemanticSegmentation]: {
    name: 'annotation.type.semanticSegmentation',
    description: 'annotation.type.semanticSegmentation.description',
    hint: 'annotation.type.semanticSegmentation.example',
    icon: <IconLayersUnion />,
    disabled: true
  },
  [LabelingType.Classification]: {
    name: 'annotation.type.classification',
    description: 'annotation.type.classification.description',
    hint: 'annotation.type.classification.example',
    icon: <IconTag />,
    disabled: true
  }
}

export const LABELING_TYPES = [
  LabelingType.ObjectDetection,
  LabelingType.InstanceSegmentation,
  LabelingType.SemanticSegmentation,
  LabelingType.Classification
]
