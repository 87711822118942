import { Box, Center, Paper, Title } from '@mantine/core'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useModelContext } from '@/providers/ModelContext'
import { LabelingType } from '@/types/dataset'
import { PageWithFixedToolbar } from '../layout/PageWithFixedToolbar/PageWithFixedToolbar'
import { PlatformAlert } from '../ui-shared/PlatformAlert/PlatformAlert'
import { LabelingTypeCardSelect } from './LabelingTypeCardSelect/LabelingTypeCardSelect'
import { LibraryModelListHandler } from './LibraryModelList/LibraryModelListHandler'

export const BaseModelSelectionPage = () => {
  const { model } = useModelContext()

  const [labelingType, setLabelingType] = useState<LabelingType | null>(
    model?.root_model?.labeling_type || null
  )

  if (labelingType === null) {
    return (
      <PageWithFixedToolbar
        toolbar={
          <Title order={3}>
            <FormattedMessage id="models.baseModel.step1.title" />
          </Title>
        }
      >
        <PlatformAlert
          title={<FormattedMessage id="models.baseModel.getStarted.title" />}
          mb="lg"
        >
          <FormattedMessage id="models.baseModel.getStarted.description" />
        </PlatformAlert>

        <Paper bg="gray.1" px={40} py={60}>
          <Center>
            <Box maw={1200}>
              <LabelingTypeCardSelect onLabelingTypeChange={setLabelingType} />
            </Box>
          </Center>
        </Paper>
      </PageWithFixedToolbar>
    )
  }

  return (
    <LibraryModelListHandler
      labelingType={labelingType}
      onLabelingTypeChange={setLabelingType}
    />
  )
}
