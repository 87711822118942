import { Badge, SimpleGrid } from '@mantine/core'
import { cloneElement } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  LABELING_TYPES,
  LABELING_TYPES_CONFIG_MAP
} from '@/components/annotations/LabelingTypes/LabelingTypeConfig'
import { LabelingType } from '@/types/dataset'
import { LabelingTypeCard } from './LabelingTypeCard'

const ComingSoonBadge = () => (
  <Badge size="xs" color="brand-secondary">
    <FormattedMessage id="general.badges.comingSoon" />
  </Badge>
)

type LabelingTypeCardSelectProps = {
  onLabelingTypeChange: (labelingType: LabelingType) => void
}

export const LabelingTypeCardSelect = ({
  onLabelingTypeChange
}: LabelingTypeCardSelectProps) => {
  return (
    <SimpleGrid cols={3} spacing="lg">
      {LABELING_TYPES.map((labelingType) => {
        const config = LABELING_TYPES_CONFIG_MAP[labelingType]

        return (
          <LabelingTypeCard
            key={labelingType}
            title={<FormattedMessage id={config.name} />}
            description={<FormattedMessage id={config.description} />}
            hint={<FormattedMessage id={config.hint} />}
            icon={cloneElement(config.icon, { size: 48 })}
            badge={config.disabled ? <ComingSoonBadge /> : undefined}
            disabled={config.disabled}
            onClick={() => onLabelingTypeChange(labelingType)}
          />
        )
      })}
    </SimpleGrid>
  )
}
