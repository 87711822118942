import {
  Box,
  Card,
  Image,
  Stack,
  Text,
  ThemeIcon,
  Tooltip
} from '@mantine/core'
import { useHover } from '@mantine/hooks'
import { IconTrash } from '@tabler/icons-react'
import { MouseEvent, MouseEventHandler } from 'react'
import { FormattedMessage } from 'react-intl'
import ImagePlaceholder from '@/assets/image-placeholder.png'
import { CameraSource } from '@/types/device'
import { SelectableCard } from '../../../../ui-shared/SelectableCard/SelectableCard'
import { OnlineStatus } from '../../../OnlineStatus/OnlineStatus'
import { CameraSourceChip } from '../CameraSourceChip/CameraSourceChip'

const IMAGE_HEIGHT = 95

type RemoveIconProps = {
  onClick: MouseEventHandler<HTMLDivElement>
}

const RemoveIcon = ({ onClick }: RemoveIconProps) => {
  const { hovered, ref } = useHover()

  const iconColor = hovered
    ? 'var(--mantine-color-red-8)'
    : 'var(--mantine-color-gray-8)'

  return (
    <Tooltip label={<FormattedMessage id="streams.remove" />}>
      <ThemeIcon ref={ref} variant="subtle" size="sm" onClick={onClick}>
        <IconTrash color={iconColor} style={{ width: '60%', height: '60%' }} />
      </ThemeIcon>
    </Tooltip>
  )
}

type SelectableCameraCardProps = {
  name: string
  thumbnailUrl: string
  source: CameraSource
  isActive: boolean
  isSelected: boolean
  disabled: boolean
  showRemoveIcon?: boolean
  onClick: () => void
  onRemoveClick: () => void
}

export const SelectableCameraCard = ({
  name,
  thumbnailUrl,
  source,
  isActive,
  isSelected,
  disabled,
  showRemoveIcon,
  onClick,
  onRemoveClick
}: SelectableCameraCardProps) => {
  const handleRemoveClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    onRemoveClick()
  }

  return (
    <SelectableCard
      isSelected={isSelected}
      disabled={disabled}
      height={175}
      onClick={onClick}
    >
      <Card.Section pos="relative" withBorder>
        <Image
          h={IMAGE_HEIGHT}
          fit="cover"
          src={thumbnailUrl}
          fallbackSrc={ImagePlaceholder}
        />

        <Box pos="absolute" top={0} right={8}>
          <CameraSourceChip source={source} />
        </Box>
      </Card.Section>

      <Card.Section pos="relative" py="sm" inheritPadding>
        <Stack align="center" gap={4}>
          <Text w="100%" ta="center" size="xs" fw="bold" lineClamp={2}>
            {name}
          </Text>

          <OnlineStatus status={isActive ? 'online' : 'offline'} />
        </Stack>

        {showRemoveIcon && (
          <Box pos="absolute" top={0} right={0}>
            <RemoveIcon onClick={handleRemoveClick} />
          </Box>
        )}
      </Card.Section>
    </SelectableCard>
  )
}
