import {
  Box,
  Center,
  Flex,
  Group,
  Loader,
  LoadingOverlay,
  Pagination,
  Text,
  Title
} from '@mantine/core'
import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { PageWithFixedToolbar } from '@/components/layout/PageWithFixedToolbar/PageWithFixedToolbar'
import { ErrorWithReload } from '@/components/ui-shared/ErrorWithReload/ErrorWithReload'
import { PlatformAlert } from '@/components/ui-shared/PlatformAlert/PlatformAlert'
import { useTableState } from '@/components/ui-shared/Tables/useTableState'
import { useApplicationContext } from '@/providers/ApplicationContext'
import { useModelContext } from '@/providers/ModelContext'
import { useGetMlModelList, useUpdateModel } from '@/queries/modelQueries'
import { ApplicationNestedPath, buildAppLink } from '@/router/paths'
import { showToast } from '@/theme/notifications'
import { LabelingType } from '@/types/dataset'
import { getPageCount, getPageOffset } from '@/utils/pagination'
import { buildSortParams } from '@/utils/sorting'
import { InferenceModal } from '../InferenceModal/InferenceModal'
import { useModelStepNavigation } from '../ModelDetails/useModelStepNavigation'
import { LabelingTypeSelect } from './LabelingTypeSelect'
import { LibraryModelGuide } from './LibraryModelGuide'
import { LibraryModelList, TableSortConfig } from './LibraryModelList'

const PAGE_SIZE = 10

const DEFAULT_SORT_CONFIG: TableSortConfig = {
  key: 'name',
  order: 'asc'
}

type LibraryModelListHandlerProps = {
  labelingType: LabelingType
  onLabelingTypeChange: (labelingType: LabelingType) => void
}

export const LibraryModelListHandler = ({
  labelingType,
  onLabelingTypeChange
}: LibraryModelListHandlerProps) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { application } = useApplicationContext()
  const { model } = useModelContext()

  const { goToDatasetSelectionScreen } = useModelStepNavigation({
    appId: application?.id || '',
    modelId: model?.id || ''
  })

  const { mutateAsync: updateModel, isPending: isUpdatePending } =
    useUpdateModel(model?.id || '')

  const [inferenceModelId, setInferenceModelId] = useState('')

  const [tableState, setTableState] = useTableState({
    activePage: 1,
    sortConfig: DEFAULT_SORT_CONFIG
  })

  const { activePage, sortConfig } = tableState

  const { data, isFetching, isLoading, isError, refetch } = useGetMlModelList({
    is_library: true,
    is_master_model: false,
    labeling_type: labelingType,
    offset: getPageOffset(activePage, PAGE_SIZE),
    limit: PAGE_SIZE,
    ordering: buildSortParams(sortConfig).ordering
  })

  const { data: masterModelsData } = useGetMlModelList({
    is_library: true,
    is_master_model: true,
    labeling_type: labelingType,
    limit: 1
  })

  const selectedModelId = model?.root_model?.id

  const libraryModels = data?.results || []

  const masterModel = masterModelsData?.results[0]

  const totalPageCount = getPageCount(data?.count || 0, PAGE_SIZE)

  useEffect(() => {
    if (totalPageCount > 0 && activePage > totalPageCount) {
      setTableState({ activePage: totalPageCount })
    }
  }, [activePage, totalPageCount, setTableState])

  const handleModelRetrain = async (baseModelId: string) => {
    if (!model) {
      return
    }

    try {
      await updateModel({
        modelId: model.id,
        data: {
          base_model_id: baseModelId
        }
      })

      void goToDatasetSelectionScreen()
    } catch {
      showToast(
        intl.formatMessage({ id: 'models.updateModelApiError' }),
        'error'
      )
    }
  }

  const handleModelDeploy = async (baseModelId: string) => {
    if (!model) {
      return
    }

    try {
      await updateModel({
        modelId: model.id,
        data: {
          base_model_id: baseModelId
        }
      })

      void navigate(
        buildAppLink(
          application?.id || '',
          ApplicationNestedPath.createDeployment
        )
      )
    } catch {
      showToast(
        intl.formatMessage({ id: 'models.updateModelApiError' }),
        'error'
      )
    }
  }

  const handleTrainFromScratch = () => {
    if (!masterModel) {
      return
    }

    void handleModelRetrain(masterModel.id)
  }

  return (
    <PageWithFixedToolbar
      toolbar={
        <Group>
          <Title order={3}>
            <FormattedMessage id="models.baseModel.step2.title" />
          </Title>

          <LabelingTypeSelect
            size="xs"
            miw={200}
            value={labelingType}
            onChange={onLabelingTypeChange}
          />
        </Group>
      }
    >
      {isLoading && (
        <Center h={120}>
          <Loader size="md" />
        </Center>
      )}

      {isError && !isLoading && libraryModels.length === 0 && (
        <ErrorWithReload onReload={() => void refetch()}>
          <FormattedMessage id="models.library.list.error" />
        </ErrorWithReload>
      )}

      {libraryModels.length === 0 && !isLoading && !isError && (
        <Center h={120}>
          <Text>
            <FormattedMessage id="models.library.list.noModels" />
          </Text>
        </Center>
      )}

      {libraryModels.length > 0 && (
        <Box pos="relative">
          <LoadingOverlay
            visible={isFetching || isUpdatePending}
            loaderProps={{ children: ' ' }} // disable loader spinner / text
          />

          {model?.root_model ? (
            <PlatformAlert variant="success" mb="lg">
              {model.root_model.is_master_model ? (
                <FormattedMessage id="models.library.list.fromScratch.selected" />
              ) : (
                <FormattedMessage
                  id="models.library.list.baseModel.selected"
                  values={{ name: <strong>{model.root_model.name}</strong> }}
                />
              )}
            </PlatformAlert>
          ) : (
            <Box mb="lg">
              <LibraryModelGuide />
            </Box>
          )}

          <LibraryModelList
            libraryModels={libraryModels}
            selectedModelId={selectedModelId}
            sortConfig={sortConfig}
            canTrainFromScratch={
              !!masterModel && masterModel.id !== selectedModelId
            }
            onSort={(sortConfig) =>
              setTableState({ activePage: 1, sortConfig })
            }
            onTest={setInferenceModelId}
            onRetrain={(baseModelId) => void handleModelRetrain(baseModelId)}
            onDeploy={(baseModelId) => void handleModelDeploy(baseModelId)}
            onTrainFromScratch={handleTrainFromScratch}
          />

          <Flex justify="end" mt="lg">
            <Pagination
              size="sm"
              total={totalPageCount}
              value={activePage}
              onChange={(value) => setTableState({ activePage: value })}
            />
          </Flex>
        </Box>
      )}

      <InferenceModal
        applicationId={application?.id}
        modelId={inferenceModelId}
        opened={inferenceModelId !== ''}
        onClose={() => setInferenceModelId('')}
      />
    </PageWithFixedToolbar>
  )
}
