import { Box, Button, Card, Text } from '@mantine/core'
import { IconArrowLeft } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router'
import { ModelStepperHandler } from '@/components/models/ModelDetails/ModelStepperHandler'
import { ModelStatus } from '@/components/models/ModelStatus/ModelStatus'
import { useApplicationContext } from '@/providers/ApplicationContext'
import { useModelContext } from '@/providers/ModelContext'
import { ApplicationNestedPath, buildAppLink } from '@/router/paths'

export const ModelDetailsSidebar = () => {
  const { application } = useApplicationContext()
  const { model } = useModelContext()

  if (!application || !model) {
    return null
  }

  return (
    <Box p={6}>
      <Button
        variant="light"
        size="xs"
        mb="lg"
        leftSection={<IconArrowLeft size={16} />}
        component={Link}
        to={buildAppLink(application.id, ApplicationNestedPath.models)}
        fullWidth
      >
        <FormattedMessage id="models.backToMyModels" />
      </Button>

      <Card mb="xl" bg="gray.1">
        <Text size="sm" fw={600} mb={4}>
          {model.name}
        </Text>

        {model.status !== null && <ModelStatus status={model.status} />}
      </Card>

      <ModelStepperHandler appId={application.id} model={model} />
    </Box>
  )
}
