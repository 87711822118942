import { getRandomColor } from '@/theme/randomColor'
import {
  BusinessLogicParameter,
  BusinessLogicRule,
  LibraryLogicType,
  NormalizedBusinessLogicParameter,
  NormalizedBusinessLogicRule,
  NormalizedStreamBusinessLogic,
  SubLabelOperator
} from '@/types/businessLogic'
import { Deployment } from '@/types/deployment'
import { CameraStreamWithDeviceId } from '@/types/device'
import { generateUUID } from '@/utils/uuid'

const buildNormalizedBusinessLogicRule = (
  rule: BusinessLogicRule
): NormalizedBusinessLogicRule => {
  return {
    operator: rule.operator,
    label_groups: rule.label_groups.map((labelGroup) => {
      const subLabel = (labelGroup.sub_labels || [])[0] || {
        operator: SubLabelOperator.With,
        label_ids: []
      }

      return {
        uuid: generateUUID(),
        type: labelGroup.type,
        label_id: labelGroup.label_id,
        threshold: labelGroup.threshold,
        sub_label_ids: subLabel.label_ids,
        sub_label_operator: subLabel.operator,
        min_dwell_time: labelGroup.min_dwell_time ?? null,
        alert_on_exit: labelGroup.alert_on_exit ?? false,
        is_ocr_enabled: labelGroup.ocr_label_ids.length > 0,
        ocr_label_ids: labelGroup.ocr_label_ids
      }
    })
  }
}

const buildNormalizedBusinessLogicParameter = (
  parameter: BusinessLogicParameter
): NormalizedBusinessLogicParameter => {
  return {
    type: parameter.parameter_type,
    name: parameter.name,
    color: getRandomColor(),
    coordinates: stringCoordinatesToArray(parameter.coordinates),
    notification: parameter.event.alert,
    upload_video: true, // TODO: revert this after ML fix is implemented VP-3262
    // upload_video: parameter.event.upload_video,
    upload_bbox_video: parameter.event.upload_bbox_video,
    rules: parameter.rules.map((rule) => {
      return buildNormalizedBusinessLogicRule(rule)
    })
  }
}

export const stringCoordinatesToArray = (coordinates: string): number[][] => {
  const coords = coordinates.split(';')
  const result = []

  for (let i = 0; i < coords.length; i += 2) {
    result.push([Number(coords[i]), Number(coords[i + 1])])
  }

  return result
}

export const buildSavedStreamLogic = (
  deployments: Deployment[],
  cameraStreams: CameraStreamWithDeviceId[]
): NormalizedStreamBusinessLogic[] => {
  return deployments
    .map((deployment) => {
      const { settings_payload } = deployment
      const streamData = cameraStreams.find(
        (stream) => stream.camera_id === settings_payload.stream_id
      )

      return settings_payload.business_logics.map((logic) => {
        return {
          logic_id: logic.id,
          logic_type: logic.logic_type as LibraryLogicType,
          logic_name: logic.name || '',
          camera_id: settings_payload.stream_id,
          camera_name: streamData?.camera_name || '',
          camera_image: streamData?.image || '',
          device_id: settings_payload.device_id,
          live_inference: settings_payload.eglsink,
          parameters: logic.parameters.map((parameter) => {
            return buildNormalizedBusinessLogicParameter(parameter)
          })
        }
      })
    })
    .flat()
}
