import {
  Button,
  CloseButton,
  Group,
  Popover,
  PopoverProps,
  Text
} from '@mantine/core'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

const POPOVER_WIDTH = 520

type LabelGroupPopoverHeaderProps = {
  title: string | ReactNode
  icon?: ReactNode
  onClose: () => void
}

export const LabelGroupPopoverHeader = ({
  title,
  icon,
  onClose
}: LabelGroupPopoverHeaderProps) => {
  return (
    <Group wrap="nowrap" justify="space-between" align="start" mb="xl">
      <Group wrap="nowrap" gap={4} align="center">
        {icon}

        <Text fw={600} size="sm">
          {title}
        </Text>
      </Group>

      <CloseButton size="sm" onClick={onClose} />
    </Group>
  )
}

type LabelGroupPopoverButtonsProps = {
  onCancel: () => void
  onSave: () => void
}

export const LabelGroupPopoverButtons = ({
  onCancel,
  onSave
}: LabelGroupPopoverButtonsProps) => {
  return (
    <Group justify="end" mt="xl" wrap="nowrap">
      <Button variant="default" size="xs" miw={100} onClick={onCancel}>
        <FormattedMessage id="cancel" />
      </Button>

      <Button size="xs" miw={100} onClick={onSave}>
        <FormattedMessage id="save" />
      </Button>
    </Group>
  )
}

export const LabelGroupPopover = ({ children, ...props }: PopoverProps) => {
  return (
    <Popover
      width={POPOVER_WIDTH}
      position="bottom"
      shadow="md"
      withOverlay
      {...props}
    >
      {children}
    </Popover>
  )
}
