import {
  Box,
  Card,
  Grid,
  LoadingOverlay,
  Stack,
  Text,
  Title
} from '@mantine/core'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { getFormattedDate } from '@/utils/date'

const VIDEO_WIDTH = 360
const VIDEO_HEIGHT = 200

const videos = [
  {
    title:
      'visionplatform.ai Demo - turn ANY camera into an AI video analytics camera',
    description:
      'Discover how visionplatform.ai turns any existing security camera into an intelligent, AI-powered solution. In this video, we take you on a walkthrough of our demo platform where you can:<br/><ul><li>Deploy pre-trained algorithms directly on NVIDIA Jetson devices or servers</li><li>Enhance and customize algorithms to meet your unique security, safety, and operational needs</li><li>Explore real-world applications that illustrate the future of smart camera technology</li></ul>',
    videoUrl: 'https://www.youtube.com/embed/n2eJX9Ns2Rg?si=ShAyIZcjXoIyh3i-',
    date: '2025-03-12'
  },
  {
    title:
      'Effortless image and video labeling with visionplatform.ai: A Step-by-Step Tutorial',
    description:
      'Unlock the power of accurate data annotation with visionplatform.ai. In this video, we guide you through our intuitive labeling process, covering everything from setting up your workspace to applying best practices for image annotation. Whether you’re preparing datasets for security, safety, or operational AI applications, learn how to streamline your workflow and boost model performance.',
    videoUrl: 'https://www.youtube.com/embed/oQF-UvGoymI?si=6bLMjSjKiMlJNzBD',
    date: '2025-03-14'
  },
  {
    title:
      'How to Build Secondary Datasets & Choose Between Single and Multi Stage Approaches',
    description:
      "In this video, we explore how to create secondary datasets that enrich your primary data and boost AI model performance on visionplatform.ai. Learn best practices for data augmentation and annotation, and understand the key considerations when choosing between a streamlined single stage approach and a more complex multi stage strategy. Whether you're enhancing security, safety, or operational applications, this guide offers actionable insights to help you optimize your workflow.",
    videoUrl: 'https://www.youtube.com/embed/ZUZ90B5nVPM?si=QYCk7l7UWImwTIDM',
    date: '2025-03-14'
  },
  {
    title:
      'Deploy Your AI Model or Application with visionplatform.ai: A Step-by-Step Guide',
    description:
      "In this video, learn how to take your AI model or application from development to production using visionplatform.ai. We’ll walk you through the deployment process—from configuring your environment on NVIDIA Jetson devices or servers with NVIDIA GPU's to launching your model in real-world scenarios. Whether you’re working on security, safety, or operational applications, this tutorial covers practical tips and best practices for a smooth, efficient deployment.",
    videoUrl: 'https://www.youtube.com/embed/tRPI4-C3Mz8?si=3rZtuSRCzTtNh2RE',
    date: '2025-04-01'
  }
]

type EmbeddedVideoProps = {
  videoUrl: string
}

const EmbeddedVideo = ({ videoUrl }: EmbeddedVideoProps) => {
  const [isLoading, setIsLoading] = useState(true)

  return (
    <Box pos="relative">
      <LoadingOverlay visible={isLoading} />

      <iframe
        width={VIDEO_WIDTH}
        height={VIDEO_HEIGHT}
        src={videoUrl}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        style={{ display: 'block' }}
        allowFullScreen
        onLoad={() => setIsLoading(false)}
      />
    </Box>
  )
}

export const LearningPage = () => {
  return (
    <>
      <Title order={2} mb="lg">
        <FormattedMessage id="tutorials.video" />
      </Title>

      <Stack gap={48}>
        {videos.map((video) => (
          <Card key={video.videoUrl} bg="gray.1" padding="xl">
            <Text size="lg" fw={600} mb="lg">
              {video.title}
            </Text>

            <Grid gutter="xl">
              <Grid.Col span="content">
                <EmbeddedVideo videoUrl={video.videoUrl} />
              </Grid.Col>

              <Grid.Col span="auto">
                <Stack>
                  <Text size="sm">
                    <span
                      dangerouslySetInnerHTML={{ __html: video.description }}
                    />
                  </Text>

                  <Text size="sm" c="dimmed">
                    {getFormattedDate(video.date)}
                  </Text>
                </Stack>
              </Grid.Col>
            </Grid>
          </Card>
        ))}
      </Stack>
    </>
  )
}
