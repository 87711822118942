import {
  ActionIcon,
  Button,
  Loader,
  Popover,
  Stack,
  Text,
  Tooltip
} from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { IconDownload, IconFileDownload } from '@tabler/icons-react'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { getEndOfDayRange } from '@/utils/date'
import { DownloadTypeSelect } from './DownloadTypeSelect'
import { DownloadAsParamsType, DownloadType } from './useDownloadAs'

type DownloadToolbarIconParams = {
  isDownloadPending: boolean
  onDownloadClick: (params: DownloadAsParamsType) => void
}

export const DownloadToolbarIcon = ({
  isDownloadPending,
  onDownloadClick
}: DownloadToolbarIconParams) => {
  const intl = useIntl()

  const [downloadType, setDownloadType] = useState<DownloadType | null>(null)

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null
  ])

  const handleDownloadButtonClick = () => {
    if (!dateRange[0] || !dateRange[1]) {
      return
    }

    const [start, end] = getEndOfDayRange(dateRange[0], dateRange[1])

    onDownloadClick({
      dateRange: {
        start_date: start,
        end_date: end
      },
      download_type: downloadType
    })
  }

  return (
    <Popover
      width={300}
      position="bottom"
      shadow="md"
      disabled={isDownloadPending}
    >
      <Popover.Target>
        <Tooltip
          arrowSize={5}
          label={
            <FormattedMessage
              id={
                isDownloadPending
                  ? 'events.download.pending'
                  : 'events.list.downloadAs'
              }
            />
          }
          withArrow
        >
          <ActionIcon variant="subtle" size="lg">
            {isDownloadPending ? (
              <Loader size={16} />
            ) : (
              <IconFileDownload color="gray" />
            )}
          </ActionIcon>
        </Tooltip>
      </Popover.Target>

      <Popover.Dropdown>
        <Stack align="stretch" justify="center" gap="md">
          <DatePickerInput
            type="range"
            valueFormat="DD-MM-YYYY"
            placeholder={intl.formatMessage({
              id: 'events.download.datepicker.chooseDateRange'
            })}
            value={dateRange}
            maxDate={new Date()}
            popoverProps={{ withinPortal: false }}
            allowSingleDateInRange
            clearable
            onChange={setDateRange}
          />

          <DownloadTypeSelect
            value={downloadType}
            onDownloadTypeChange={setDownloadType}
          />

          <Button
            leftSection={<IconDownload size={14} />}
            color="brand-primary"
            variant="outline"
            disabled={
              isDownloadPending || dateRange[0] === null || !downloadType
            }
            onClick={handleDownloadButtonClick}
          >
            <Text size="sm">
              <FormattedMessage id="download" />
            </Text>
          </Button>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  )
}
