import { Group, Text, ThemeIcon } from '@mantine/core'
import { IconCircleFilled } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { MLModelStatus } from '@/types/model'

type ModelStatusProps = {
  status: MLModelStatus
}

const StatusText: Record<MLModelStatus, string> = {
  [MLModelStatus.Draft]: 'models.status.draft',
  [MLModelStatus.PreTrained]: 'models.status.pretrained',
  [MLModelStatus.Trained]: 'models.status.trained',
  [MLModelStatus.Evaluated]: 'models.status.evaluated',
  [MLModelStatus.Deployed]: 'models.status.deployed'
}

const StatusColor: Record<MLModelStatus, string> = {
  [MLModelStatus.Draft]: 'gray',
  [MLModelStatus.PreTrained]: 'orange',
  [MLModelStatus.Trained]: 'green',
  [MLModelStatus.Evaluated]: 'green',
  [MLModelStatus.Deployed]: 'blue'
}

export const ModelStatus = ({ status }: ModelStatusProps) => {
  return (
    <Group gap={4} wrap="nowrap" align="center">
      <ThemeIcon variant="transparent" color={StatusColor[status]} size={12}>
        <IconCircleFilled style={{ width: '100%', height: '100%' }} />
      </ThemeIcon>

      <Text size="xs" ta="center">
        <FormattedMessage id={StatusText[status]} />
      </Text>
    </Group>
  )
}
