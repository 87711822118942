import { ActionIcon, Menu, Space, Tooltip } from '@mantine/core'
import { IconBook, IconDownload } from '@tabler/icons-react'
import { PropsWithChildren } from 'react'
import { FormattedMessage } from 'react-intl'
import { PLATFORM_NAME } from '@/config/main'

const PLATFORM_MANUAL =
  'https://drive.google.com/file/d/1NV7jhjpKQ3fnXpPoEMNDX63__8M9-bzq/view?usp=sharing'

const JETSON_MANUAL =
  'https://drive.google.com/file/d/1SKPIXZnP2Rj6jU9ezhf5FkmjqHdKrr4a/view?usp=sharing'

const MILESTONE_XPROTECT_MANUAL =
  'https://drive.google.com/file/d/1uu1e60jQmGafkR3koCuXKaE7AQqDfmhy/view?usp=sharing'

const MILESTONE_INTEGRATOR_MANUAL =
  'https://drive.google.com/file/d/1ZMKJfcP-fIy2_3CHO1RlOqF1o1wAyW8U/view?usp=sharing'

type DownloadLinkProps = PropsWithChildren<{
  href: string
}>

const DownloadLink = ({ href, children }: DownloadLinkProps) => (
  <Menu.Item
    rightSection={<IconDownload size={16} />}
    component="a"
    target="_blank"
    href={href}
  >
    {children}
  </Menu.Item>
)

export const PlatformManuals = () => {
  return (
    <Menu
      width={360}
      styles={(theme) => ({
        label: {
          color: 'white',
          fontWeight: 600,
          fontSize: theme.fontSizes.xs,
          backgroundColor: theme.colors['brand-primary'][5]
        },
        itemLabel: {
          color: theme.colors['brand-primary'][5],
          fontWeight: 600,
          fontSize: theme.fontSizes.xs
        }
      })}
    >
      <Menu.Target>
        <Tooltip label={<FormattedMessage id="manuals.title" />}>
          <ActionIcon>
            <IconBook style={{ width: '80%', height: '80%' }} />
          </ActionIcon>
        </Tooltip>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>
          <FormattedMessage id="manuals.title" />
        </Menu.Label>

        <Space h="xs" />

        <DownloadLink href={PLATFORM_MANUAL}>
          <FormattedMessage
            id="manuals.platform.download"
            values={{
              platform: PLATFORM_NAME
            }}
          />
        </DownloadLink>

        <DownloadLink href={JETSON_MANUAL}>
          <FormattedMessage id="manuals.jetson.download" />
        </DownloadLink>

        <DownloadLink href={MILESTONE_XPROTECT_MANUAL}>
          <FormattedMessage id="manuals.milestone.xprotect.download" />
        </DownloadLink>

        <DownloadLink href={MILESTONE_INTEGRATOR_MANUAL}>
          <FormattedMessage id="manuals.milestone.integrator.download" />
        </DownloadLink>
      </Menu.Dropdown>
    </Menu>
  )
}
