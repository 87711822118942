import {
  Anchor,
  Badge,
  Box,
  Card,
  Group,
  NumberInput,
  Popover,
  Radio,
  Stack,
  ThemeIcon,
  ThemeIconProps,
  Tooltip
} from '@mantine/core'
import { IconBell } from '@tabler/icons-react'
import { ReactNode, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { PlatformAlert } from '@/components/ui-shared/PlatformAlert/PlatformAlert'
import {
  LabelGroupPopover,
  LabelGroupPopoverButtons,
  LabelGroupPopoverHeader
} from '../LabelGroupPopover/LabelGroupPopover'

type AlertIconProps = {
  size?: ThemeIconProps['size']
}

const AlertIcon = ({ size = 'sm' }: AlertIconProps) => {
  return (
    <ThemeIcon variant="transparent" color="brand-primary" size={size}>
      <IconBell style={{ width: '80%', height: '80%' }} stroke={3} />
    </ThemeIcon>
  )
}

type AlertConfigProps = {
  alertType: 'alert_on_enter' | 'alert_on_exit'
  dwellTime: number | null
  isAlertOnExitDisabled?: boolean
  alertOnExitDisabledTooltip?: string | ReactNode
  onChange: (
    alertType: 'alert_on_enter' | 'alert_on_exit',
    dwellTime: number | null
  ) => void
}

export const AlertConfig = ({
  alertType,
  dwellTime,
  isAlertOnExitDisabled,
  alertOnExitDisabledTooltip,
  onChange
}: AlertConfigProps) => {
  const intl = useIntl()

  const [isPopoverOpened, setIsPopoverOpened] = useState(false)
  const [cachedAlertType, setCachedAlertType] = useState(alertType)
  const [cachedDwellTime, setCachedDwellTime] = useState<number | ''>(
    dwellTime ?? ''
  )

  const handleSave = () => {
    onChange(
      cachedAlertType,
      cachedDwellTime === '' || cachedAlertType === 'alert_on_exit'
        ? null
        : cachedDwellTime
    )

    setIsPopoverOpened(false)
  }

  return (
    <LabelGroupPopover opened={isPopoverOpened} onChange={setIsPopoverOpened}>
      <Popover.Target>
        <Anchor
          component="button"
          type="button"
          size="xs"
          styles={{
            root: {
              textTransform: 'lowercase'
            }
          }}
          onClick={() => setIsPopoverOpened((o) => !o)}
        >
          <Group wrap="nowrap" gap={4} align="center">
            <AlertIcon size="xs" />

            {alertType === 'alert_on_enter' ? (
              <FormattedMessage id="logic.alert.onEnter.label" />
            ) : (
              <FormattedMessage id="logic.alert.onExit.label" />
            )}

            <Badge size="xs">
              <FormattedMessage id="logic.alert.yes" />
            </Badge>

            {alertType === 'alert_on_enter' && dwellTime && (
              <Badge
                size="xs"
                styles={{
                  label: {
                    textTransform: 'lowercase'
                  }
                }}
              >
                <FormattedMessage
                  id="logic.alert.seconds"
                  values={{
                    value: dwellTime
                  }}
                />
              </Badge>
            )}
          </Group>
        </Anchor>
      </Popover.Target>

      <Popover.Dropdown>
        <LabelGroupPopoverHeader
          title={<FormattedMessage id="logic.alert.configure" />}
          icon={<AlertIcon size="sm" />}
          onClose={() => setIsPopoverOpened(false)}
        />

        <Stack gap="xl" mb="xl">
          <Box>
            <Radio
              mb="sm"
              checked={cachedAlertType === 'alert_on_enter'}
              label={<FormattedMessage id="logic.alert.onEnter.label" />}
              description={
                <FormattedMessage id="logic.alert.onEnter.description" />
              }
              styles={{
                label: {
                  fontWeight: 600
                }
              }}
              onChange={() => setCachedAlertType('alert_on_enter')}
            />

            {cachedAlertType === 'alert_on_enter' && (
              <Card bg="gray.1" pl="xl">
                <NumberInput
                  maw={240}
                  value={cachedDwellTime}
                  placeholder={intl.formatMessage({
                    id: 'logic.alert.dwellTime.placeholder'
                  })}
                  label={<FormattedMessage id="logic.alert.dwellTime.label" />}
                  description={
                    <FormattedMessage id="logic.alert.dwellTime.description" />
                  }
                  inputWrapperOrder={['label', 'input', 'description', 'error']}
                  min={0}
                  max={999999} // around 11.5 days
                  onChange={(value) =>
                    setCachedDwellTime(typeof value === 'number' ? value : '')
                  }
                />
              </Card>
            )}
          </Box>

          <Tooltip
            w={220}
            disabled={!isAlertOnExitDisabled}
            label={alertOnExitDisabledTooltip}
            position="bottom-start"
            refProp="rootRef"
            multiline
          >
            <Radio
              disabled={isAlertOnExitDisabled}
              checked={cachedAlertType === 'alert_on_exit'}
              label={<FormattedMessage id="logic.alert.onExit.label" />}
              description={
                <FormattedMessage id="logic.alert.onExit.description" />
              }
              styles={{
                label: {
                  fontWeight: 600
                }
              }}
              onChange={() => setCachedAlertType('alert_on_exit')}
            />
          </Tooltip>
        </Stack>

        <PlatformAlert variant="info" mb="xl" compact>
          <FormattedMessage id="logic.alert.info" />
        </PlatformAlert>

        <LabelGroupPopoverButtons
          onCancel={() => setIsPopoverOpened(false)}
          onSave={handleSave}
        />
      </Popover.Dropdown>
    </LabelGroupPopover>
  )
}
