import { Box, Button, Group, Text } from '@mantine/core'
import { useDisclosure, useInterval } from '@mantine/hooks'
import { IconChecklist, IconRefresh } from '@tabler/icons-react'
import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router'
import { DatasetPreview } from '@/components/datasets/DatasetPreview/DatasetPreview'
import { InferenceModal } from '@/components/models/InferenceModal/InferenceModal'
import { ErrorWithReload } from '@/components/ui-shared/ErrorWithReload/ErrorWithReload'
import { useGetDatasetDetails } from '@/queries/datasetQueries'
import { useGetTrainingDetails } from '@/queries/trainingQueries'
import { ApplicationNestedPath, buildAppLink } from '@/router/paths'
import { MLModel } from '@/types/model'
import { TrainingStatus } from '@/types/training'
import { CreateTrainingHandler } from '../CreateTrainingHandler'
import { TrainingDrawer } from '../TrainingDrawer/TrainingDrawer'
import { TrainingCompletedBanner } from './TrainingCompletedBanner/TrainingCompletedBanner'
import { TrainingInProgressBanner } from './TrainingInProgressBanner/TrainingInProgressBanner'
import { TrainingParamsTable } from './TrainingParamsTable'

const POLL_INTERVAL = 10000

type TrainingDetailsProps = {
  trainingId: string
  appId: string
  model: MLModel
}

export const TrainingDetails = ({
  trainingId,
  appId,
  model
}: TrainingDetailsProps) => {
  const [
    isInferenceModalOpened,
    { open: openInferenceModal, close: closeInferenceModal }
  ] = useDisclosure(false)

  const [
    isTrainingDrawerOpened,
    { open: openTrainingDrawer, close: closeTrainingDrawer }
  ] = useDisclosure(false)

  const [
    isDatasetPreviewOpened,
    { open: openDatasetPreview, close: closeDatasetPreview }
  ] = useDisclosure(false)

  const {
    data: trainingData,
    isError,
    isFetching,
    refetch
  } = useGetTrainingDetails(trainingId)

  const { data: datasetData } = useGetDatasetDetails(
    model.dataset_version?.dataset || '',
    !!model.dataset_version?.dataset
  )

  const { start, stop, active } = useInterval(
    () => void refetch(),
    POLL_INTERVAL
  )

  useEffect(() => {
    if (trainingData) {
      if (trainingData.status === TrainingStatus.Running && !active) {
        start()
      }

      if (
        trainingData.status === TrainingStatus.Completed ||
        trainingData.status === TrainingStatus.Failed
      ) {
        stop()
      }
    }
  }, [trainingData, active, start, stop])

  // Cleanup function to stop the interval when the component is unmounted
  useEffect(() => {
    return stop
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (isError) {
    return (
      <ErrorWithReload onReload={() => void refetch()}>
        <FormattedMessage id="training.fetchError" />
      </ErrorWithReload>
    )
  }

  return (
    <>
      <Box
        style={{
          opacity: isFetching ? 0.7 : 1
        }}
      >
        <Text fw="bold" mb="md">
          <FormattedMessage id="training.results" />
        </Text>

        {trainingData?.status === TrainingStatus.Running && (
          <Box mb="lg">
            <TrainingInProgressBanner
              rightSection={
                <Button
                  variant="light"
                  size="xs"
                  leftSection={<IconRefresh size={16} />}
                  onClick={() => void refetch()}
                >
                  {isFetching ? (
                    <FormattedMessage id="refreshing" />
                  ) : (
                    <FormattedMessage id="refresh" />
                  )}
                </Button>
              }
            />
          </Box>
        )}

        {trainingData?.status === TrainingStatus.Completed && (
          <Box mb="lg">
            <TrainingCompletedBanner
              rightSection={
                <Group justify="center">
                  <Button
                    miw={160}
                    leftSection={<IconChecklist size={16} />}
                    onClick={openInferenceModal}
                  >
                    <FormattedMessage id="models.test" />
                  </Button>

                  <Button
                    miw={160}
                    component={Link}
                    to={buildAppLink(appId, ApplicationNestedPath.models)}
                  >
                    <FormattedMessage id="models.viewMyModels" />
                  </Button>
                </Group>
              }
            />
          </Box>
        )}

        {trainingData && (
          <TrainingParamsTable
            training={trainingData}
            modelName={model.name}
            baseModelName={model.base_model?.name || ''}
            labels={model.dataset_version?.labels || []}
            datasetName={datasetData?.name || ''}
            isDatasetDeleted={!model.dataset_version?.dataset}
            onRetry={openTrainingDrawer}
            onDatasetPreview={openDatasetPreview}
          />
        )}
      </Box>

      <InferenceModal
        applicationId={appId}
        modelId={model.id}
        resolution={trainingData?.resolution}
        opened={isInferenceModalOpened}
        onClose={closeInferenceModal}
      />

      <TrainingDrawer
        opened={isTrainingDrawerOpened}
        onClose={closeTrainingDrawer}
      >
        {trainingData && (
          <CreateTrainingHandler
            applicationId={appId}
            model={model}
            training={trainingData}
            onCancel={closeTrainingDrawer}
            onTrainingCreated={closeTrainingDrawer}
          />
        )}
      </TrainingDrawer>

      <DatasetPreview
        opened={isDatasetPreviewOpened}
        datasetId={model.dataset_version?.dataset}
        filteredVersionId={model.dataset_version?.id}
        onClose={closeDatasetPreview}
      />
    </>
  )
}
