import { Affix, Button, Flex, Stack } from '@mantine/core'
import { IconX } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { Chatbot } from './Chatbot/Chatbot'

type FloatingChatbotProps = {
  opened: boolean
  onClose: () => void
}

export const FloatingChatbot = ({ opened, onClose }: FloatingChatbotProps) => {
  return (
    opened && (
      <Affix position={{ bottom: 10, right: 10 }}>
        <Stack gap={2}>
          <Flex justify="end">
            <Button
              variant="subtle"
              color="gray.4"
              size="sm"
              rightSection={<IconX size={16} />}
              onClick={onClose}
            >
              <FormattedMessage id="close" />
            </Button>
          </Flex>

          <Chatbot maxWidth={360} />
        </Stack>
      </Affix>
    )
  )
}
