import { Container, ContainerProps, rem } from '@mantine/core'
import { ReactNode } from 'react'

export const PAGE_MAX_WIDTH = 1600

// eslint-disable-next-line react-refresh/only-export-components
export const PAGE_PADDING_X = {
  base: 'md',
  md: 'xl'
}

type PageContainerProps = {
  children: ReactNode
  fullWidth?: boolean
  maxWidth?: number
  py?: ContainerProps['py']
}

export const PageContainer = ({
  children,
  fullWidth,
  maxWidth = PAGE_MAX_WIDTH,
  py = 'xl'
}: PageContainerProps) => {
  return (
    <Container
      py={py}
      px={PAGE_PADDING_X}
      size={fullWidth ? '100%' : rem(maxWidth)}
    >
      {children}
    </Container>
  )
}
