import {
  ActionIcon,
  Anchor,
  Box,
  Button,
  Group,
  NumberInput,
  Select,
  Stack,
  Text
} from '@mantine/core'
import { FormErrors } from '@mantine/form'
import { IconCirclePlus, IconTrash } from '@tabler/icons-react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  BusinessLogicRuleOperator,
  LabelGroupType,
  LibraryLogicType,
  NormalizedBusinessLogicRule,
  NormalizedLabelGroup,
  ParameterType,
  SubLabelOperator
} from '@/types/businessLogic'
import { useDeploymentStepperContext } from '../../DeploymentStepperContext'
import { AlertConfig } from '../AlertConfig/AlertConfig'
import { OcrConfig, OcrConfigValues } from '../OcrConfig/OcrConfig'
import { ObjectMultiSelect } from './ObjectMultiSelect'
import { ObjectSelect } from './ObjectSelect'

const resetOcrValues = {
  is_ocr_enabled: false,
  ocr_label_ids: []
}

type RuleBuilderProps = {
  logicType: LibraryLogicType
  logicParameterType: ParameterType
  ruleOperator: NormalizedBusinessLogicRule['operator']
  labelGroups: NormalizedLabelGroup[]
  errors: FormErrors
  onLabelGroupAdd: () => void
  onLabelGroupRemove: (index: number) => void
  onLabelGroupChange: (index: number, labelGroup: NormalizedLabelGroup) => void
  onOperatorChange: (value: NormalizedBusinessLogicRule['operator']) => void
}
export const RuleBuilder = ({
  logicType,
  logicParameterType,
  ruleOperator,
  labelGroups,
  errors,
  onLabelGroupAdd,
  onLabelGroupRemove,
  onLabelGroupChange,
  onOperatorChange
}: RuleBuilderProps) => {
  const intl = useIntl()

  const { groupedLabelOptions, labels } = useDeploymentStepperContext()

  const errorMessage = Object.values(errors)[0]

  const handleLabelChange = (index: number, label_id: string) => {
    onLabelGroupChange(index, {
      ...labelGroups[index],
      ...resetOcrValues,
      label_id
    })
  }

  const handleMakeGroup = (index: number) => {
    onLabelGroupChange(index, {
      ...labelGroups[index],
      type: LabelGroupType.Group
    })
  }

  const handleSubLabelChange = (index: number, label_ids: string[]) => {
    onLabelGroupChange(index, {
      ...labelGroups[index],
      ...resetOcrValues,
      sub_label_ids: label_ids
    })
  }

  const handleSubLabelOperatorChange = (
    index: number,
    operator: NormalizedLabelGroup['sub_label_operator']
  ) => {
    onLabelGroupChange(index, {
      ...labelGroups[index],
      sub_label_operator: operator
    })
  }

  const handleThresholdChange = (index: number, threshold: number | '') => {
    onLabelGroupChange(index, {
      ...labelGroups[index],
      threshold: threshold === '' ? 0 : threshold
    })
  }

  const handleAlertConfigChange = (
    index: number,
    alertType: 'alert_on_enter' | 'alert_on_exit',
    dwellTime: number | null
  ) => {
    onLabelGroupChange(index, {
      ...labelGroups[index],
      ...(alertType === 'alert_on_exit' ? resetOcrValues : {}), // Disable OCR when alert on exit is enabled
      alert_on_exit: alertType === 'alert_on_exit',
      min_dwell_time: dwellTime
    })
  }

  const handleOcrConfigChange = (index: number, config: OcrConfigValues) => {
    onLabelGroupChange(index, {
      ...labelGroups[index],
      is_ocr_enabled: config.isOcrEnabled,
      ocr_label_ids: config.ocrLabelIds
    })
  }

  return (
    <>
      {logicType === LibraryLogicType.ObjectDetection && (
        <Text size="sm">
          <FormattedMessage id="logic.objectDetection.labelName" />
        </Text>
      )}

      {logicType === LibraryLogicType.ObjectCount && (
        <Text size="sm">
          <FormattedMessage id="logic.objectCount.labelName" />
        </Text>
      )}

      {labelGroups.map((labelGroup, index) => (
        <Stack key={labelGroup.uuid} gap={0}>
          <Box pos="relative" py={24}>
            <Group wrap="nowrap" gap={2} align="start">
              <ObjectSelect
                w="100%"
                value={labelGroup.label_id}
                data={groupedLabelOptions}
                onChange={(value) => handleLabelChange(index, value as string)}
              />

              {labelGroup.type === LabelGroupType.Group && (
                <>
                  <Select
                    miw={90}
                    size="xs"
                    value={labelGroup.sub_label_operator}
                    allowDeselect={false}
                    data={[
                      {
                        value: SubLabelOperator.With,
                        label: intl.formatMessage({
                          id: 'logic.rules.group.operator.with'
                        })
                      },
                      {
                        value: SubLabelOperator.Without,
                        label: intl.formatMessage({
                          id: 'logic.rules.group.operator.without'
                        })
                      }
                    ]}
                    comboboxProps={{
                      width: 160,
                      position: 'bottom-start'
                    }}
                    checkIconPosition="right"
                    onChange={(value) =>
                      handleSubLabelOperatorChange(
                        index,
                        value as NormalizedLabelGroup['sub_label_operator']
                      )
                    }
                  />

                  <ObjectMultiSelect
                    miw={160}
                    value={labelGroup.sub_label_ids}
                    data={groupedLabelOptions}
                    onChange={(value) => handleSubLabelChange(index, value)}
                  />
                </>
              )}

              {logicType === LibraryLogicType.ObjectCount && (
                <NumberInput
                  size="xs"
                  min={0}
                  miw={70}
                  value={labelGroup.threshold}
                  onChange={(value) =>
                    handleThresholdChange(index, value as number | '')
                  }
                />
              )}

              <ActionIcon
                variant="subtle"
                size="xs"
                radius="xl"
                mt={4}
                onClick={() => onLabelGroupRemove(index)}
              >
                <IconTrash style={{ width: '80%', height: '80%' }} />
              </ActionIcon>
            </Group>

            {labelGroup.type === LabelGroupType.Single &&
              labelGroup.label_id && (
                <Anchor
                  pos="absolute"
                  top={5}
                  right={26}
                  component="button"
                  type="button"
                  size="xs"
                  onClick={() => handleMakeGroup(index)}
                >
                  <FormattedMessage id="logic.rules.object.makeGroup" />
                </Anchor>
              )}

            {labelGroup.label_id &&
              logicParameterType === ParameterType.ROI && (
                <Group mt="xs" gap="lg">
                  <AlertConfig
                    alertType={
                      labelGroup.alert_on_exit
                        ? 'alert_on_exit'
                        : 'alert_on_enter'
                    }
                    dwellTime={labelGroup.min_dwell_time}
                    isAlertOnExitDisabled={
                      logicType !== LibraryLogicType.ObjectDetection
                    }
                    alertOnExitDisabledTooltip={
                      <FormattedMessage id="logic.alert.onExit.disabledTooltip" />
                    }
                    onChange={(alertType, dwellTime) =>
                      handleAlertConfigChange(index, alertType, dwellTime)
                    }
                  />

                  <OcrConfig
                    disabled={labelGroup.alert_on_exit}
                    ocrConfig={{
                      isOcrEnabled: labelGroup.is_ocr_enabled,
                      ocrLabelIds: labelGroup.ocr_label_ids
                    }}
                    labels={labels.filter(
                      (label) =>
                        labelGroup.label_id === label.id ||
                        labelGroup.sub_label_ids.includes(label.id)
                    )}
                    onChange={(ocrConfig) =>
                      handleOcrConfigChange(index, ocrConfig)
                    }
                  />
                </Group>
              )}
          </Box>

          {index < labelGroups.length - 1 && (
            <Select
              maw={72}
              size="xs"
              checkIconPosition="right"
              radius="xl"
              value={ruleOperator}
              allowDeselect={false}
              data={[
                {
                  value: BusinessLogicRuleOperator.OR,
                  label: intl.formatMessage({ id: 'logic.rules.operator.or' })
                },
                {
                  value: BusinessLogicRuleOperator.AND,
                  label: intl.formatMessage({ id: 'logic.rules.operator.and' })
                }
              ]}
              onChange={(value) =>
                onOperatorChange(
                  value as NormalizedBusinessLogicRule['operator']
                )
              }
            />
          )}
        </Stack>
      ))}

      {errorMessage && (
        <Text size="xs" mb="sm" c="red">
          {errorMessage}
        </Text>
      )}

      <Button
        size="xs"
        mt="md"
        leftSection={<IconCirclePlus size={16} />}
        onClick={onLabelGroupAdd}
      >
        <FormattedMessage id="logic.rules.object.add" />
      </Button>
    </>
  )
}
