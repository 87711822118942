import { Box, Card, Flex, Stack, Text, UnstyledButton } from '@mantine/core'
import { useHover } from '@mantine/hooks'
import { ReactNode } from 'react'

type LabelingTypeCardProps = {
  title: string | ReactNode
  description: string | ReactNode
  hint: string | ReactNode
  icon: ReactNode
  badge?: ReactNode
  disabled?: boolean
  onClick?: () => void
}

export const LabelingTypeCard = ({
  title,
  description,
  hint,
  icon,
  badge,
  disabled,
  onClick
}: LabelingTypeCardProps) => {
  const { hovered, ref } = useHover()
  const cardShadow = hovered ? 'sm' : undefined

  return (
    <UnstyledButton disabled={disabled} onClick={onClick}>
      <Card
        ref={ref}
        padding="xl"
        pos="relative"
        h="100%"
        shadow={cardShadow}
        styles={{
          root: {
            opacity: disabled ? 0.6 : 1,
            cursor: disabled ? 'not-allowed' : 'pointer'
          }
        }}
        withBorder
      >
        <Stack align="center" gap="lg">
          <Flex direction="column" align="center" justify="center" mih={100}>
            {icon}
          </Flex>

          <Text size="md" fw={600} ta="center">
            {title}
          </Text>

          <Text size="sm" ta="center">
            {description}
          </Text>

          <Text size="xs" fw={600} ta="center">
            {hint}
          </Text>
        </Stack>

        {badge && (
          <Box pos="absolute" top={0} right={8}>
            {badge}
          </Box>
        )}
      </Card>
    </UnstyledButton>
  )
}
