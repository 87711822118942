import { Anchor, Button, Center } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { Outlet } from 'react-router'
import { AuthLayout } from '@/components/layout/AuthLayout/AuthLayout'
import { PlatformAlert } from '@/components/ui-shared/PlatformAlert/PlatformAlert'
import { useContactInfoPopup } from '@/hooks/useContactInfoPopup'
import { useSession } from '@/session/SessionContext'
import { useLogout } from '@/session/useLogout'

export const SubscriptionChecker = () => {
  const { isAuthenticated, userSubscription } = useSession()
  const { open: openContactInfo } = useContactInfoPopup()
  const { logout } = useLogout()

  if (isAuthenticated && userSubscription === null) {
    return (
      <AuthLayout variant="large">
        <PlatformAlert variant="warning" mb="xl" compact>
          <FormattedMessage
            id="subscriptions.noActiveSubscription"
            values={{
              link: (chunks) => (
                <Anchor
                  size="xs"
                  underline="always"
                  component="button"
                  type="button"
                  onClick={openContactInfo}
                >
                  {chunks}
                </Anchor>
              )
            }}
          />
        </PlatformAlert>

        <Center>
          <Button size="xs" onClick={() => void logout()}>
            <FormattedMessage id="logout" />
          </Button>
        </Center>
      </AuthLayout>
    )
  }

  return <Outlet />
}
