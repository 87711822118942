export type LicenseProduct = {
  Product_Name: string
  Description: string
  Product_Category: 'license' | 'streams'
  Unit_Price: number
  Price_Currency: string
  Billing_Period: 'monthly' | 'yearly'
  Number_of_Streams: number
  Number_of_Users: number
  Number_of_Apps: number
  Extra_Camera_Stream_per_Month_EUR: number
  Model_Trainings_per_Month: number
  Version_Control_Models: number
  Free_Test_Images_per_Month: number
  Fast_Training: boolean
  Event_Storage_GB: number
  Event_Retention_Months: number
  Custom_API_Access: boolean
  Labeling_Service: boolean
  Labeling_Price_per_Bounding_Box_EUR: number
}

export enum SubscriptionStatus {
  Active = 'Active',
  Cancelled = 'Cancelled',
  Suspended = 'Suspended',
  Upgraded = 'Upgraded'
}

export type Subscription = {
  subscription_id: string
  ordered_items: {
    product: LicenseProduct
  }[]
  status: SubscriptionStatus
  extra_streams: number
  created_at: string
  updated_at: string
}
