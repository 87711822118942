import * as Sentry from '@sentry/react'
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { PlatformLoader } from '@/components/ui-shared/PlatformLoader/PlatformLoader'
import { PermissionsMap } from '@/types/permissions'
import { Subscription } from '@/types/subscription'
import { User } from '@/types/user'
import { WorkspaceMember } from '@/types/workspace'
import { UserSession, getUserSession } from './helpers'

type LogoutReason = 'user_logout' | ''

export type SessionContextProps = {
  isAuthenticated: boolean
  user: User | null
  userSubscription: Subscription | null
  workspaceMemberships: WorkspaceMember[]
  activeWorkspaceMembership: WorkspaceMember | null
  permissions: PermissionsMap
  isRefreshing: boolean
  logoutReason: LogoutReason
  clearSession: (logoutReason?: LogoutReason) => void
  refreshSession: () => Promise<void>
}

const initialValues = {
  isAuthenticated: false,
  user: null,
  userSubscription: null,
  workspaceMemberships: [],
  activeWorkspaceMembership: null,
  permissions: {},
  isRefreshing: false,
  logoutReason: '' as LogoutReason,
  clearSession: () => {},
  refreshSession: async () => {}
}

const SessionContext = createContext<SessionContextProps>(initialValues)

type SessionProviderProps = {
  children: ReactNode
}

export const SessionProvider = ({ children }: SessionProviderProps) => {
  const [userSession, setUserSession] = useState<UserSession | null>(null)
  const [isReady, setIsReady] = useState(false)
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [logoutReason, setLogoutReason] = useState<LogoutReason>('')

  const userSubscription = userSession?.subscriptions[0] || null

  const workspaceMemberships = userSession?.workspaceMemberships || []

  const activeWorkspaceMembership =
    workspaceMemberships.find((membership) => membership.is_selected) || null

  const clearSession = (reason?: LogoutReason) => {
    setUserSession(null)
    setLogoutReason(reason || '')
    Sentry.setUser(null)
  }

  const handleSetUserSession = (session: UserSession) => {
    setUserSession(session)

    Sentry.setUser({
      id: session.user.id
    })
  }

  useEffect(() => {
    const initUserSession = async () => {
      setLogoutReason('')

      try {
        const session = await getUserSession()
        handleSetUserSession(session)
      } catch {
        clearSession('')
      } finally {
        setIsReady(true)
      }
    }

    void initUserSession()
  }, []) // // eslint-disable-next-line react-hooks/exhaustive-deps

  const refreshSession = useCallback(async () => {
    setLogoutReason('')
    setIsRefreshing(true)

    try {
      const session = await getUserSession()
      handleSetUserSession(session)
    } catch {
      clearSession('')
    }

    setIsRefreshing(false)
  }, [])

  return (
    <SessionContext.Provider
      value={{
        isAuthenticated: Boolean(userSession),
        user: userSession?.user || null,
        userSubscription,
        workspaceMemberships,
        activeWorkspaceMembership,
        permissions: userSession?.permissions || {},
        isRefreshing,
        logoutReason,
        clearSession,
        refreshSession
      }}
    >
      {isReady ? children : <PlatformLoader />}
    </SessionContext.Provider>
  )
}

// eslint-disable-next-line react-refresh/only-export-components
export const useSession = () => {
  return useContext(SessionContext)
}
