import { HoverCard, Table, ThemeIcon } from '@mantine/core'
import { IconInfoCircle } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { MLModel } from '@/types/model'

type LibraryModelMoreInfoProps = {
  libraryModel: MLModel
}

export const LibraryModelMoreInfo = ({
  libraryModel
}: LibraryModelMoreInfoProps) => {
  return (
    <HoverCard width={480} shadow="md">
      <HoverCard.Target>
        <ThemeIcon variant="light" radius="xl" size="sm">
          <IconInfoCircle style={{ width: '80%', height: '80%' }} />
        </ThemeIcon>
      </HoverCard.Target>

      <HoverCard.Dropdown>
        <Table variant="vertical" layout="fixed" withTableBorder>
          <Table.Tbody>
            <Table.Tr>
              <Table.Th w={160}>
                <FormattedMessage id="models.library.list.info.name" />
              </Table.Th>

              <Table.Td>{libraryModel.name}</Table.Td>
            </Table.Tr>

            <Table.Tr>
              <Table.Th w={160}>
                <FormattedMessage id="models.library.list.info.description" />
              </Table.Th>
              {/* TODO add description when supported */}
              <Table.Td>-</Table.Td>
            </Table.Tr>
          </Table.Tbody>
        </Table>
      </HoverCard.Dropdown>
    </HoverCard>
  )
}
