import { ReactNode, createContext, useContext } from 'react'
import { ApplicationType } from '@/types/app'
import { Label } from '@/types/label'
import { MLModel } from '@/types/model'

type LabelOption = {
  value: string
  label: string
  color: string
}

type ContextProps = {
  appType: ApplicationType
  primaryModel: MLModel | null
  secondaryModel: MLModel | null
  labels: Label[]
  groupedLabelOptions: {
    group: string
    items: LabelOption[]
  }[]
}

const initialValues = {
  appType: ApplicationType.SingleStage,
  primaryModel: null,
  secondaryModel: null,
  labels: [],
  groupedLabelOptions: []
}

const DeploymentStepperContext = createContext<ContextProps>(initialValues)

type DeploymentStepperProviderProps = {
  appType: ApplicationType
  primaryModel: MLModel | null
  secondaryModel: MLModel | null
  children: ReactNode
}

export const DeploymentStepperProvider = ({
  appType,
  primaryModel,
  secondaryModel,
  children
}: DeploymentStepperProviderProps) => {
  const primaryLabels = (
    primaryModel?.dataset_version?.labels ||
    primaryModel?.base_model?.dataset_version?.labels ||
    []
  ).sort((a, b) => a.name.localeCompare(b.name))

  const secondaryLabels = (
    secondaryModel?.dataset_version?.labels ||
    secondaryModel?.base_model?.dataset_version?.labels ||
    []
  )
    .filter((label) => !primaryLabels.some((l) => l.id === label.id))
    .sort((a, b) => a.name.localeCompare(b.name))

  const labels = [...primaryLabels, ...secondaryLabels]

  const groupedLabelOptions = [
    {
      group: primaryModel?.name || '',
      items: primaryLabels.map((label) => ({
        value: label.id,
        label: label.name,
        color: label.color
      }))
    },
    ...(appType === ApplicationType.MultiStage
      ? [
          {
            group: secondaryModel?.name || '',
            items: secondaryLabels.map((label) => ({
              value: label.id,
              label: label.name,
              color: label.color
            }))
          }
        ]
      : [])
  ]

  return (
    <DeploymentStepperContext.Provider
      value={{
        appType,
        primaryModel,
        secondaryModel,
        labels,
        groupedLabelOptions
      }}
    >
      {children}
    </DeploymentStepperContext.Provider>
  )
}

// eslint-disable-next-line react-refresh/only-export-components
export const useDeploymentStepperContext = () => {
  return useContext(DeploymentStepperContext)
}
