import { PaginatedResponse } from '@/types/pagination'
import { Subscription, SubscriptionStatus } from '@/types/subscription'
import { vpApi } from './vpApi'

type SubscriptionsResponse = PaginatedResponse<Subscription[]>

type SubscriptionListParams = {
  limit?: number
  offset?: number
  ordering?: string
  status?: SubscriptionStatus
}

const getSubscriptions = async (params?: SubscriptionListParams) => {
  const res = await vpApi.get<SubscriptionsResponse>('/v1/subscriptions/', {
    params
  })

  return res.data
}

export const subscriptionApi = {
  getSubscriptions
}
